//GA events handler 
export function sendMessageGA (){
    window.dataLayer.push({
        event: 'custom_click',
        Type: 'Send message'
      });
}

export function clickStarsGA (){
    window.dataLayer.push({
        event: 'custom_click',
        Type: 'Click stars',
      });
}

export function sendCommentGA (){
    window.dataLayer.push({
        event: 'custom_click',
        Type: 'Send comment',
      });
}

export function copyMessageTextGA (){
    window.dataLayer.push({
        event: 'custom_click',
        Type: 'Copy message text',
      });
}

export function copyLinkGA (){
  window.dataLayer.push({
      event: 'custom_click',
      Type: 'Copy link',
    });
}

export function switchModeGA (){
    window.dataLayer.push({
        event: 'custom_click',
        Type: 'Switch mode',
      });
}

export function switchLanguageGA (){
  window.dataLayer.push({
      event: 'custom_click',
      Type: 'Switch language',
    });
}

export function translateGA (){
  window.dataLayer.push({
      event: 'custom_click',
      Type: 'Translate',
    });
}