import { useDispatch } from 'react-redux';
import { setTheme } from '../../slice/SettingSlice';
import { switchModeGA } from '../../GoogleAnalytics4/GoogleAnalyticsEvents';

function SwitchTheme() {
    const dispatch = useDispatch();

    return (
        <>
            <span name='theme-switch' onClick={(e) => { e.stopPropagation(); dispatch(setTheme()); switchModeGA() }}>
                <div>
                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none">
                        <g clipPath="url(#clip0_130_3132)">
                            <path d="M7.99996 9.99999C8.55552 9.99999 9.02774 9.80555 9.41663 9.41666C9.80552 9.02777 9.99996 8.55555 9.99996 7.99999C9.99996 7.44444 9.80552 6.97221 9.41663 6.58332C9.02774 6.19444 8.55552 5.99999 7.99996 5.99999C7.44441 5.99999 6.97218 6.19444 6.58329 6.58332C6.1944 6.97221 5.99996 7.44444 5.99996 7.99999C5.99996 8.55555 6.1944 9.02777 6.58329 9.41666C6.97218 9.80555 7.44441 9.99999 7.99996 9.99999ZM7.99996 11.3333C7.07774 11.3333 6.29163 11.0083 5.64163 10.3583C4.99163 9.70832 4.66663 8.92221 4.66663 7.99999C4.66663 7.07777 4.99163 6.29166 5.64163 5.64166C6.29163 4.99166 7.07774 4.66666 7.99996 4.66666C8.92218 4.66666 9.70829 4.99166 10.3583 5.64166C11.0083 6.29166 11.3333 7.07777 11.3333 7.99999C11.3333 8.92221 11.0083 9.70832 10.3583 10.3583C9.70829 11.0083 8.92218 11.3333 7.99996 11.3333ZM3.33329 8.66666H0.666626V7.33332H3.33329V8.66666ZM15.3333 8.66666H12.6666V7.33332H15.3333V8.66666ZM7.33329 3.33332V0.666656H8.66663V3.33332H7.33329ZM7.33329 15.3333V12.6667H8.66663V15.3333H7.33329ZM4.26663 5.16666L2.58329 3.54999L3.53329 2.56666L5.13329 4.23332L4.26663 5.16666ZM12.4666 13.4333L10.85 11.75L11.7333 10.8333L13.4166 12.45L12.4666 13.4333ZM10.8333 4.26666L12.45 2.58332L13.4333 3.53332L11.7666 5.13332L10.8333 4.26666ZM2.56663 12.4667L4.24996 10.85L5.16663 11.7333L3.54996 13.4167L2.56663 12.4667Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_130_3132">
                                <rect width="16" height="16" fill="#5abeba" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.64">
                            <path d="M8 14C6.33333 14 4.91667 13.4167 3.75 12.25C2.58333 11.0833 2 9.66667 2 8C2 6.33333 2.58333 4.91667 3.75 3.75C4.91667 2.58333 6.33333 2 8 2C8.15556 2 8.30833 2.00556 8.45833 2.01667C8.60833 2.02778 8.75556 2.04444 8.9 2.06667C8.44444 2.38889 8.08056 2.80833 7.80833 3.325C7.53611 3.84167 7.4 4.4 7.4 5C7.4 6 7.75 6.85 8.45 7.55C9.15 8.25 10 8.6 11 8.6C11.6111 8.6 12.1722 8.46389 12.6833 8.19167C13.1944 7.91944 13.6111 7.55556 13.9333 7.1C13.9556 7.24444 13.9722 7.39167 13.9833 7.54167C13.9944 7.69167 14 7.84444 14 8C14 9.66667 13.4167 11.0833 12.25 12.25C11.0833 13.4167 9.66667 14 8 14ZM8 12.6667C8.97778 12.6667 9.85556 12.3972 10.6333 11.8583C11.4111 11.3194 11.9778 10.6167 12.3333 9.75C12.1111 9.80556 11.8889 9.85 11.6667 9.88333C11.4444 9.91667 11.2222 9.93333 11 9.93333C9.63333 9.93333 8.46944 9.45278 7.50833 8.49167C6.54722 7.53056 6.06667 6.36667 6.06667 5C6.06667 4.77778 6.08333 4.55556 6.11667 4.33333C6.15 4.11111 6.19444 3.88889 6.25 3.66667C5.38333 4.02222 4.68056 4.58889 4.14167 5.36667C3.60278 6.14444 3.33333 7.02222 3.33333 8C3.33333 9.28889 3.78889 10.3889 4.7 11.3C5.61111 12.2111 6.71111 12.6667 8 12.6667Z" fill="#344D66" />
                        </g>
                    </svg>
                </div>
            </span>
        </>
    );
}

export default SwitchTheme;