import { createSlice } from '@reduxjs/toolkit';

const initialState={ inputMessage: '', send: false, urlText:'', linebreak: false};

export const InputMessageSlice = createSlice({
    name: 'inputmessage',
    initialState,
    reducers: {
        setInputMessage: (state, action) => {
            state.inputMessage = action.payload;
        },
        setSend: (state, action) => {
            state.send = action.payload;
        },
        setUrlText: (state, action) => {
            state.urlText = action.payload;
        },
        setLineBreak: (state, action) => {
            state.linebreak = action.payload;
        }

    }
});

export const { setInputMessage, setSend, setUrlText,setLineBreak} = InputMessageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectInputMessage = (state) => state.inputmessage.inputMessage;
export const selectSend = (state) => state.inputmessage.send;
export const selectUrlText = (state) => state.inputmessage.urlText;
export const selectLineBreak = (state) => state.inputmessage.linebreak;

export default InputMessageSlice.reducer;