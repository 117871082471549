import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { selectRegistrationSuccess, setShowRegistrationSuccess } from '../../../slice/ModalVisibilitySlice';
import { selectTheme } from '../../../slice/SettingSlice.js';
import { isMobile } from 'react-device-detect';

function RegistrationSuccess() {
  const show = useSelector(selectRegistrationSuccess);
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);

  const handleClose = () => {
    dispatch(setShowRegistrationSuccess(false));
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false}>
        <Modal.Header>
          <Modal.Title className='title'>
            <svg className='modal-icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 20v-2.8c0-.55.142-1.067.425-1.55.283-.483.675-.85 1.175-1.1.85-.433 1.808-.8 2.875-1.1C7.542 13.15 8.717 13 10 13c.5 0 .988.025 1.463.075.475.05.937.125 1.387.225l-1.75 1.75a3.004 3.004 0 0 0-.537-.05H10c-1.183 0-2.246.142-3.188.425-.941.283-1.712.592-2.312.925a.973.973 0 0 0-.5.85v.8h6.25l2 2H2zm13.55.4-3.45-3.45 1.4-1.4 2.05 2.05 5.05-5.05 1.4 1.4-6.45 6.45zM10 12c-1.1 0-2.042-.392-2.825-1.175C6.392 10.042 6 9.1 6 8s.392-2.042 1.175-2.825C7.958 4.392 8.9 4 10 4s2.042.392 2.825 1.175C13.608 5.958 14 6.9 14 8s-.392 2.042-1.175 2.825C12.042 11.608 11.1 12 10 12zm0-2c.55 0 1.02-.196 1.412-.588C11.804 9.021 12 8.55 12 8c0-.55-.196-1.02-.588-1.412A1.926 1.926 0 0 0 10 6c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 8 8c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588z" fill="#5ABEBA" />
            </svg>
            Registration successful</Modal.Title>
          <Button onClick={handleClose} id='close' className='close-button'>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <div className='center' style={{ fontWeight: '500' }}>Thank you for registering. <br /> Please check your email to activate your account.
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RegistrationSuccess;