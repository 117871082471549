const optionsList = [
        { value: 'AR', name: 'Arabic' },
        { value: 'BG', name: 'Bulgarian' },
        { value: 'ZH', name: 'Chinese' },
        { value: 'CS', name: 'Czech' },
        { value: 'DA', name: 'Danish' },
        { value: 'NL', name: 'Dutch' },
        { value: 'EN', name: 'English' },
        { value: 'ET', name: 'Estonian' },
        { value: 'FI', name: 'Finnish' },
        { value: 'FR', name: 'French' },
        { value: 'DE', name: 'German' },
        { value: 'EL', name: 'Greek' },
        { value: 'HU', name: 'Hungarian' },
        { value: 'ID', name: 'Indonesian' },
        { value: 'IT', name: 'Italian' },
        { value: 'JA', name: 'Japanese' },
        { value: 'KO', name: 'Korean' },
        { value: 'LV', name: 'Latvian' },
        { value: 'LT', name: 'Lithuanian' },
        { value: 'NB', name: 'Norwegian' },
        { value: 'PL', name: 'Polish' },
        { value: 'PT-PT', name: 'Portuguese' },
        { value: 'RO', name: 'Romanian' },
        { value: 'RU', name: 'Russian' },
        { value: 'SK', name: 'Slovak' },
        { value: 'SL', name: 'Slovenian' },
        { value: 'ES', name: 'Spanish' },
        { value: 'SV', name: 'Swedish' },
        { value: 'TR', name: 'Turkish' },
        { value: 'UK', name: 'Ukrainian' },
    ];

export default optionsList;