import { createSlice } from '@reduxjs/toolkit'; //da eliminare se teniamo il menù fisso

const initialState={ showside: false };

export const SideMenuSlice = createSlice({
    name: 'sidemenu',
    initialState,
    reducers: {
        setShowSide: (state,action) => {
            state.showside = action.payload;
        },
        setInitiSideMenu: ()=> {
            return initialState
        }
    }
});

export const { setShowSide, setInitiSideMenu } = SideMenuSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectShowSide = (state) => state.sidemenu.showside;

export default SideMenuSlice.reducer;