import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { selectMailForgotSent, setMailForgotSent } from '../../../slice/ModalVisibilitySlice';
import { selectTheme } from '../../../slice/SettingSlice.js';
import { isMobile } from 'react-device-detect';

function MailForgotSent() {
  const show = useSelector(selectMailForgotSent);
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);

  const handleClose = () => {
    dispatch(setMailForgotSent(false));
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false}>
        <Modal.Header>
          <Modal.Title className='title'>
            <svg className='modal-icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="m15.95 22-4.25-4.25 1.4-1.4 2.85 2.85 5.65-5.65 1.4 1.4L15.95 22zM12 11l8-5H4l8 5zm0 2L4 8v10h5.15l2 2H4c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4h16c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v4.35l-2 2V8l-8 5z" fill="#5ABEBA" />
            </svg>
            Email sent</Modal.Title>
          <Button onClick={handleClose} id='close' className='close-button'>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <div className='center' style={{fontWeight:'500'}}>An email has been sent to your address.<br /> Please check your email to reset your password.
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MailForgotSent;