import { createSlice } from '@reduxjs/toolkit';
import {isMobile} from 'react-device-detect';

const initialState={device: (isMobile && window.matchMedia("(orientation: portrait)").matches ? 'phone' : 'pc')};

export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        setDevice: (state, action) =>{
            state.device= action.payload;
        }
    }
});

export const { setDevice } = deviceSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectDevice =(state) => state.device.device;

export default deviceSlice.reducer;