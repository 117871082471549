import { useDispatch, useSelector } from "react-redux";
import { setInputMessage } from "../../slice/InputMessageSlice";
import examples from '../../constants/example';
import arrow from '../../Images/arrow.svg';
import { selectDevice } from "../../slice/deviceSlice";

function ExampleList(props) {
    const dispatch = useDispatch();
    const device = useSelector(selectDevice);

    const htmlEx = examples[props.type].exArray.map((es, i) => {
        return <li className='example' key={i} onClick={() => { dispatch(setInputMessage(es.ex)) }} style={device === 'pc' ? { marginRight: '16px' } : {}}><span>{es.ex} </span><img src={arrow} alt='arrow' /></li>
    })

    return (
        <>
            {examples[props.type].text === undefined ? null : <div className="mb-2">{examples[props.type].text}</div>}
            {examples[props.type].title === undefined ? null : <div className="mb-2"><b>{examples[props.type].title}</b></div>}
            <ul className='mb-3 mt-3 exampleList'>
                {htmlEx}
            </ul>
            {examples[props.type].note === undefined ? null : <div className="mb-2"><b>Note:</b> {examples[props.type].note}</div>}
        </>
    )
}

export default ExampleList;