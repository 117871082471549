import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Country from './Country.js';
import TooltipPass from './TooltipPass.js';
import TooltipUser from './TooltipUser.js';
import Password from './Password.js';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSignUp, selectSignUp, setShowLogin, setShowRegistrationSuccess, setTerms, setPrivacy } from '../../../slice/ModalVisibilitySlice.js';
import { useRef, useState } from 'react';
import { selectPassword, setPasswordInit } from '../../../slice/passwordSlice.js';
import { setErrModalVal, selectErrModalVal, setErrModalValInit } from '../../../slice/errModalValidationSlice.js';
import { Url } from '../../../constants/global.js';
import { resetLanguage, selectLanguage, selectTheme } from '../../../slice/SettingSlice.js';
import { isMobile } from 'react-device-detect';
import SelectLanguage from '../ProfileSettings/SelectLanguage.js';
import { selectBearer } from '../../../slice/bearerSlice.js';
import { selectUserData } from '../../../slice/userDataSlice.js';
import AccountIcon from '../../../Images/AccountIcon.js';

function SignUp() {
    const show = useSelector(selectSignUp);
    const [data, setData] = useState('');
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [country1, setCountry1] = useState(''); //lascio per settare la props della select, ma se uso il userDataSlice, tolgo e passo quello
    const password = useSelector(selectPassword);
    const errValidation = useSelector(selectErrModalVal);
    let errSignUp;
    let errPass;
    const theme = useSelector(selectTheme);
    const [disable, setDisable] = useState(false);
    const formRef = useRef('');
    const preferredLanguage = useSelector(selectLanguage);
    const logToken = useSelector(selectBearer);
    const username = useSelector(selectUserData).user;

    const handleClose = () => {
        dispatch(setShowSignUp(false));
        setValidated(false);
        dispatch(setPasswordInit());
        setCountry1('');
        setData('');
        dispatch(setErrModalValInit());
        setDisable(false);
        dispatch(resetLanguage());
    };

    function returnLogin() {
        if(!disable){
            handleClose();
            dispatch(setShowLogin({ show: true, type: 'logButton' }));
        }
    }

    function termsOfUseClick() {
        dispatch(setTerms(true));
    }

    function privacyPolicyClick() {
        dispatch(setPrivacy(true));
    }

    function handleSubmit(event) {
        const form = event.currentTarget;
        setValidated(true);
        event.preventDefault();
        if (form.checkValidity() === false || errPass !== "") {
            event.stopPropagation();
            formRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        } else {
            setDisable(true);
            const fetchPromise = fetch(Url + '/varchat/api/auth/sign-up', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    firstName: data.firstname,
                    lastName: data.lastname,
                    username: data.user,
                    email: data.email,
                    institution: data.aff,
                    country: country1,
                    password: password.pass,
                    lang: preferredLanguage
                })
            });

            fetchPromise
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 409) {
                            dispatch(setErrModalVal({ show: true, value: 'Username/email already present' }));
                        } else {
                            dispatch(setErrModalVal({ show: true, value: 'An error occurred.' }));
                        }
                        throw new Error(`HTTP error: ${response.status}`);
                    } else {
                        return response.json();
                    }
                })
                .then(() => {
                    handleClose();
                    dispatch(setShowRegistrationSuccess(true));
                })
                .catch((error) => {
                    console.error(`Could not get products: ${error}`);
                    setDisable(false);
                });
        }
    }

    function handleChange() {
        dispatch(setErrModalValInit());
    }

    if (errValidation.show) {
        errSignUp = <p className='mt-2 errorMessage'>{errValidation.value}</p>
    } else {
        errSignUp = <></>
    }

    if (password.pass !== password.confirm) {
        errPass = "Passwords do not match.";
    } else {
        errPass = "";
    }

    //html elem to render the 2 different views (pc and phone)
    const firstName = <><label>FIRST NAME</label>
        <Form.Control type="text" id='firstname' placeholder="First name" autoFocus='autofocus' required minLength='3' pattern='([A-Za-zÀ-ÖØ-öø-ÿ]+[\s\.]*){3,}' onChange={(e) => { setData({ ...data, firstname: e.target.value }); handleChange() }} />
        <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid name.</Form.Control.Feedback></>;

    const lastName = <><label>LAST NAME</label>
        <Form.Control type="text" id='lastname' placeholder="Last name" required minLength={3} pattern="([A-Za-zÀ-ÖØ-öø-ÿ]+[\s\.']*){3,}" onChange={(e) => { setData({ ...data, lastname: e.target.value }); handleChange() }} />
        <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid last name.</Form.Control.Feedback></>;

    const user = <><span className='label-with-info'><label>USERNAME</label>
        <TooltipUser /></span>
        <Form.Control type="text" id='user' placeholder="Eg:Name.surname" required pattern='[A-Za-z0-9À-ÖØ-öø-ÿ\.]*' maxLength={32} onChange={(e) => { setData({ ...data, user: e.target.value }); handleChange() }} />
        <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid username.</Form.Control.Feedback></>;

    const email = <><label>EMAIL ADDRESS</label>
        <Form.Control type="email" placeholder="name@example.com" required onChange={(e) => { setData({ ...data, email: e.target.value }); handleChange() }} />
        <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid email.</Form.Control.Feedback></>;

    const aff = <><label>ORGANIZATION</label>
        <Form.Control type="text" id='aff' placeholder="Affiliation" required pattern='[A-zÀ-ú0-9\s\-_\.]*[A-zÀ-ú]{3,}[A-zÀ-ú0-9\s\-_\.]*' minLength={3} onChange={(e) => { setData({ ...data, aff: e.target.value }); handleChange() }} />
        <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid organization.</Form.Control.Feedback></>;

    const country = <><label>COUNTRY</label>
        <Country value='required' mystate={[country1, setCountry1]} />
        <Form.Control.Feedback type="invalid" className='invalid'>Please select a country.</Form.Control.Feedback></>;

    const passwordEl = <><span className='label-with-info'><label>PASSWORD</label>
        <TooltipPass />
    </span>
        <Password value='pass'></Password>
        <Form.Control.Feedback type="invalid" className='invalid'>Please enter a valid password.</Form.Control.Feedback></>;

    const confirmPass = <><label>CONFIRM PASSWORD</label>
        <Password value='confirm'></Password>
        <span className='invalid-password'>{validated ? errPass : null}</span></>;

    const language = <><label>PREFERRED LANGUAGE</label>
        <SelectLanguage/>
    </>

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false} dialogClassName={'modal-46w'} >
                <Modal.Header>
                    <Modal.Title className='title'>
                        <AccountIcon />
                        Create an account</Modal.Title>
                    <Button onClick={() => handleClose()} id='close' className='close-button' disabled={disable}>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                {logToken !== '' ? <div className='center' style={{fontSize:'large'}}>You are already registered as {username}. <br/>If you want to create a new account please log out first.</div> :
                    <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef} style={{ pointerEvents : disable ? 'none' : 'auto'}}>
                        <Container style={{padding: '0px'}}>
                            {isMobile ? <><Row className='input-row'>{firstName}</Row>
                                <Row className='input-row'>{lastName}</Row>
                                <Row className='input-row'>{user}</Row>
                                <Row className='input-row'>{email}</Row>
                                <Row className='input-row'>{aff}</Row>
                                <Row className='input-row'>{country}</Row>
                                <Row className='input-row'>{language}</Row>
                                <Row className='input-row'>{passwordEl}</Row>
                                <Row className='input-row'>{confirmPass}</Row></>
                                : <><Row className='input-row'>
                                    <Col>{firstName}</Col>
                                    <Col>{lastName}</Col></Row>
                                    <Row className='input-row'>
                                        <Col>{email}</Col>
                                        <Col>{user}</Col></Row>
                                    <Row className='input-row'>
                                        <Col>{aff}</Col>
                                        <Col>{country}</Col>
                                        </Row>
                                    <Row className='input-row'>
                                        <Col>{language}</Col>
                                    </Row>
                                    <Row className='input-row'>
                                        <Col>{passwordEl}</Col>
                                        <Col>{confirmPass}</Col></Row>
                                </>}
                            <Row className='mb-2'><Col className="myCheckbox">
                                <input type="checkbox" name="check" id="checkbox_id1" required onChange={handleChange} />
                                <span></span>
                                <label htmlFor="checkbox_id1" className='check lab'>I agree to the <span onClick={(e) => { e.preventDefault(); termsOfUseClick(); }}>Terms of Use</span></label>
                                <Form.Control.Feedback type="invalid" className='invalid'>Please accept our Term of Use.</Form.Control.Feedback>
                            </Col>
                            {isMobile ? null : <Col className="myCheckbox">
                                <input type="checkbox" id="checkbox_id3" name="check3" />
                                <span></span>
                                <label htmlFor="checkbox_id3" className='check lab'>Subscribe to our Newsletters</label>
                            </Col>}
                            </Row>
                            <Row className={isMobile ? 'mb-2' : 'mb-3'}><Col className="myCheckbox">
                                <input type="checkbox" name="check2" id="checkbox_id2" required onChange={handleChange} />
                                <span></span>
                                <label htmlFor="checkbox_id2" className='check lab'>I agree to the <span onClick={(e) => { e.preventDefault(); privacyPolicyClick(); }}>Privacy Policy</span></label>
                                <Form.Control.Feedback type="invalid" className='invalid'>Please accept our Privacy Policy.</Form.Control.Feedback>
                            </Col></Row>
                            {isMobile ?
                            <Row className='mb-3'><Col className="myCheckbox">
                                <input type="checkbox" id="checkbox_id3" name="check3" />
                                <span></span>
                                <label htmlFor="checkbox_id3" className='check lab'>Subscribe to our Newsletters</label>
                            </Col></Row> : null}
                            <Row><Col><Button className="primary-button" type='submit' id='signup' disabled={disable}>
                                SIGN UP
                            </Button></Col></Row>
                            <Row>{errSignUp}</Row>
                            <Row className='center mt-4'>
                                <div className="lab">Do you have an account? <span onClick={returnLogin} className='link-green'>Log In</span></div>
                            </Row>
                        </Container>
                    </Form>}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SignUp;