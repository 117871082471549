import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { selectPrivacy, setPrivacy } from '../../../slice/ModalVisibilitySlice';
import './PrivacyPolicy.css';
import { isMobile } from 'react-device-detect';
import { selectTheme } from '../../../slice/SettingSlice';

function PrivacyPolicy() {
    const show = useSelector(selectPrivacy);
    const dispatch = useDispatch();
    const theme = useSelector(selectTheme);

    const handleClose = (e) => {
        e.stopPropagation();
        dispatch(setPrivacy(false));
    };

    return (
        <>
            <Modal show={show} onHide={(e)=>handleClose(e)} backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false} dialogClassName={!isMobile ? 'modal-dim' : ''}>
                <Modal.Header>
                    <Modal.Title className='title'>
                        <svg className='modal-icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.95 15.55L16.6 9.9L15.175 8.475L10.95 12.7L8.85 10.6L7.425 12.025L10.95 15.55ZM12 22C9.68333 21.4167 7.77083 20.0875 6.2625 18.0125C4.75417 15.9375 4 13.6333 4 11.1V5L12 2L20 5V11.1C20 13.6333 19.2458 15.9375 17.7375 18.0125C16.2292 20.0875 14.3167 21.4167 12 22ZM12 19.9C13.7333 19.35 15.1667 18.25 16.3 16.6C17.4333 14.95 18 13.1167 18 11.1V6.375L12 4.125L6 6.375V11.1C6 13.1167 6.56667 14.95 7.7 16.6C8.83333 18.25 10.2667 19.35 12 19.9Z" fill="#5ABEBA" />
                        </svg>Privacy Policy</Modal.Title>
                    <Button onClick={handleClose} id='close' className='close-button'>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body className="body" id="privacy-policy-modal">
                    <Container >
                        <Row>
                            <div className='center'><b>Related to the enGenome VarChat services</b></div>
                            <div><p>Dear VarChat User,</p>
                                <p>Surely you know the European Data Protection Regulation n. 679/2016 (also known as “GDPR”), the regulation about the protection of natural persons with regards to the processing of personal data and concerning the free movement of such data.</p>
                                <p>Pursuant to this Regulation and the Italian law, we share the necessary information for you to understand how we process your data collected via the VarChat services.</p>
                                <p>So, we invite you to read this page addressing any questions you may have. If you have any other questions, contact us. We are committed to protecting you and being transparent about our process.</p>
                                <p>Let’s go!</p>
                                <ul>
                                    <li>
                                        <b>Who is the Data Controller?</b><br />
                                        <p><b>enGenome S.r.l.</b> – VAT n.: 09236350964<br />
                                            with registered office in via Ferrata n. 5 – 27100 – Pavia (PV)<br />
                                            <b>e-mail</b>: <a className='link-generic' href='mailto:info@engenome.com'>info@engenome.com</a><br />
                                            <b>Tel.</b>: <a className='link-generic' href='tel:+3903821851951'>(+39) 0382.18.51.951</a></p></li>
                                </ul>
                                <p>enGenome S.r.l is the data controller (hereinafter "<b>enGenome</b>" and/or "<b>Data Controller</b>") of Users personal
                                    data (hereinafter also "<b>Data subject</b>") that use the VarChat services via website
                                    (<a className='link-generic' href='https://varchat.engenome.com/' target='_blank' rel='noreferrer'>https://varchat.engenome.com</a>), including any subdomains (hereinafter "<b>Site</b>").</p>
                                <ul><li>
                                    <b>How can I contact the DPO?</b>
                                    <p><b>e-mail</b>: <a className='link-generic' href="mailto:privacy@engenome.com">privacy@engenome.com</a><br />
                                        <b>Tel.</b>: <a className='link-generic' href='tel:+393791610212'>(+39) 379 1610212</a></p>

                                </li></ul>
                                <p>Through the Site, enGenome provides a set of “<b>Services”</b>. Please read our Terms and Conditions for further
                                    details.</p>
                                <ul> <li>
                                    <b>What Data do we process with the Site?</b>
                                    <ul className='mb-3'>
                                        <li>
                                            First and last name
                                        </li>
                                        <li>
                                            IP address, device and browser information
                                        </li>
                                        <li>
                                            Cookie e pixel (in accordance with our cookie policy)
                                        </li>
                                        <li>
                                            E-mail address, Username and password
                                        </li>
                                        <li>
                                            Affiliation and Country
                                        </li>
                                        <li>
                                            Field of work and research interests (optional)
                                        </li>
                                    </ul>
                                </li>
                                </ul>
                                <p>
                                    enGenome does not need to process further data than that described above. The additional information that
                                    the Data Subject provides in the Site will be processed in accordance with this Privacy Policy, the Terms and
                                    Conditions and the provisions of the European and national legislation (hereinafter this data will be jointly
                                    considered as "<b>Data</b>").
                                </p>
                                <table name='privacy-table'>
                                    <thead>
                                        <tr>
                                            <th>What are the purposes of the Data processing?</th>
                                            <th>What are the legal basis of the Data processing?</th>
                                            <th>What is the Data retention?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>A. Allow access to the Site</th>
                                            <td>Legitimate interest of the Data Controller in providing its Services, and of the User himself to correctly use the Site</td>
                                            <td>See our Cookie Policy</td>
                                        </tr>
                                        <tr>
                                            <th>B. Answer requests for information about the Services</th>
                                            <td>Legitimate interest of the Data Controller in answering to any requests from Users</td>
                                            <td>The Data will only be kept for the duration necessary for the execution of the request</td>
                                        </tr>
                                        <tr>
                                            <th>C. Conclude contracts related to the Services and fulfill contractual obligations with the Users (contractual purposes)</th>
                                            <td>Fulfillment and contractual management for the provision of the Services offered. The provision of Data is necessary for the correct provision of the Services</td>
                                            <td rowSpan={2}>The Data will be kept for a period equal to the duration of the Contract (any renewals included) and for the next 10 years (only related to tax and fiscal data, unless otherwise provided in accordance with the EU and national legislation)</td>
                                        </tr>
                                        <tr>
                                            <th>D. Fulfilling the administrative and accounting obligations provided for by the legislation (e.g. on anti-money laundering, accounting management and invoicing)</th>
                                            <td style={{ borderRight: '1px solid var(--blue-10)' }}>Legitimate interest of the Data Controller in fulfilling the legal obligations</td>
                                        </tr>
                                        <tr>
                                            <th>E. For the promotion of the Services through traditional and remote communication tools (marketing purposes, e.g. by Newsletter)</th>
                                            <td>Consent. This is optional and Users can withdraw it at any time</td>
                                            <td>For a maximum period of 24 months from the consent of the Data Subject and in accordance with our Cookie Policy</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>The Data is processed with manual or IT tools, also through automated tools, suitable to guarantee its security, confidentiality and to avoid unauthorized access.</p>
                                <p>The Data processed is stored using cloud computing tools on servers located within the EU territory (Italy): for
                                    more information on safety standards and compliance with the requirements set by the GDPR adopted by the
                                    selected external providers, consult the dedicated <a className='link-generic' href='https://www.aruba.com/us/privacy-policy' target='_blank' rel='noreferrer'>web page</a>.</p>
                                <p>After the data retention indicated above, the Data will be destroyed, deleted or anonymized, compatible with
                                    the technical procedures of deletion and backup.</p>
                                <ul>
                                    <li>
                                        <b>To whom is the Data communicated?</b>
                                        <ul className='mb-3'>
                                            <li>
                                                Service providers related to the activities of the Data Controller and public institutions
                                            </li>
                                            <li>
                                                Service providers related to assistance, tax and legal advice
                                            </li>
                                            <li>
                                                Service providers related to IT or storage and external service providers
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>The Data collected through the Site will be processed only to ensure the correct use of the Services and may
                                    be viewed and used by the enGenome team (employees and collaborators) appropriately authorized to
                                    process them.</p>
                                <p>
                                    The Data may be communicated for legitimate interest purposes, to suppliers of assistance services, technical,
                                    tax and legal consultancy, assignees of receivables in the context of credit securitization or credit assignment
                                    operations for strictly connected and instrumental purposes to the management of the relationship with the
                                    transferred Data Subject, as well as to the issue of securities, assignees of Company or business unit, potential
                                    buyers of enGenome and companies resulting from possible mergers, divisions or other transformations of the
                                    enGenome, also in the context of the activities functional to these operations, and to competent authorities.
                                </p>
                                <p>
                                    The above-mentioned subjects may act, as appropriate, as external data controllers or independent data
                                    controllers in accordance with current legislation. You can request the updated list of companies to which the
                                    Data will be communicated at any time to the Data Controller, by means of a specific request to be sent using
                                    the contact information indicated in this Privacy Policy.
                                </p>
                                <ul>
                                    <li>
                                        <b>Is the Data transferred abroad?</b>
                                        <p>The Data will mainly be processed within the national territory and
                                            the European Union, but could also be transferred to extra EU
                                            Countries.</p>
                                        <p>Any transfer of the Data of the Data Subject to countries located
                                            outside the European Union will take place only in compliance with
                                            adequate guarantees for the purposes of the transfer itself (and in
                                            particular complying with the provisions of art. 45, 46 and 49 of
                                            GDPR), e.g. provided that the European Commission has confirmed
                                            that the third country has an adequate level of data protection or
                                            other data protection guarantees exist, such as binding business rules
                                            or signing up to standard EU contractual clauses.</p>
                                    </li>
                                </ul>
                                <p>In any case, the processing of data is carried out in accordance with the national and supranational provisions
                                    in force in the field (Legislative Decree 196/2003, as amended and supplemented by Legislative Decree
                                    101/2018, and EU Regulation 679/2016). The Data Subject will have the right to obtain from the data
                                    controller a copy of the Data held abroad and to obtain information about the place where such data is stored
                                    making an express request to be sent using the contacts indicated in this document.</p>
                                <ul>
                                    <li>
                                        <b>What are the Data Subject rights?</b>
                                        <p style={{ marginBottom: '0' }}>By contacting the Data Controller and the DPO (using the above
                                            mentioned e-mail address) or the other contact information above,
                                            you can ask enGenome at any time:</p>
                                        <ul>
                                            <li>access to the Data concerning you, the rectification of
                                                inaccurate Data, the integration of incomplete Data, their
                                                deletion (right to be forgotten), the limitation of processing in
                                                the cases provided for art. 18 GDPR;</li>
                                            <li>to receive in a structured format, common use and readable
                                                by automatic device the Data concerning you in the cases
                                                provided for by art. 20 GDPR; and, if technically feasible, to
                                                transmit such data to another data controller without
                                                hindrance;</li>
                                            <li>to withdraw the consent given at any time; as well as oppose
                                                at any time the processing of Data pursuant to art. 21 GDPR,
                                                giving evidence of the reasons justifying the opposition;</li>
                                            <li>any other request for clarification regarding the processing of
                                                Data carried out by the Data Controller.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>Finally, we remind you that it is always possible to make a complaint to the competent Supervisory Authority
                                    (Guarantor for the Protection of Personal Data) pursuant to art. 77 of the GDPR, if it considers that the
                                    processing carried out by the Data Controller is contrary to the actual legislation.</p>
                                <p>The Data Controller may make changes and/or additions to this Privacy Policy, also as a result of changes in the
                                    applicable legislation. You can view the text of the Privacy Policy constantly updated at the our Site in the
                                    Privacy Policy section or make an explicit request by contacting the Data Controller or the DPO directly using
                                    the contact information indicated above.</p>
                                <span className='span-align-right'><b>Pavia, 25.01.2024</b></span>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal >
        </>
    );
}

export default PrivacyPolicy;