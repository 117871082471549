import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { selectShowSide, setShowSide } from "../../slice/SideMenuSlice";

function MenuButton() {
    const dispatch = useDispatch();
    const openMenu = useSelector(selectShowSide);

    function clickMenu() {
        dispatch(setShowSide(!openMenu));
    }

    return (
        <Button onClick={() => { clickMenu() }} className='phone-button-header'>
            {openMenu ? <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path fill="#ffffff" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                : <FontAwesomeIcon icon={faBars} style={{width:'24px'}}/>
            }
        </Button>
    );
}

export default MenuButton