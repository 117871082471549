import { useSelector, useDispatch } from 'react-redux';
import { selectInfo, setInfo } from '../../../slice/ModalVisibilitySlice';
import SendArrow from '../../../Images/SendArrow';
import { selectDevice } from '../../../slice/deviceSlice';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

function InfoPage() {
    const dispatch = useDispatch();
    const device = useSelector(selectDevice);
    const [width, setWidth] = useState(0);
    const backRef = useRef(null);
    const headerRef = useRef(null);
    const padding = device === 'pc' ? 48 : 32; //somma dei padding sx e dx
    const show = useSelector(selectInfo);

    useEffect(() => {
        setWidth(headerRef.current.offsetWidth - (backRef.current.offsetWidth * 2) - padding);
    });

    const handleClose = () => {
        dispatch(setInfo(false));
    };

    return (
        <div style={show ? { height: '100%' } : { display: 'none' }}>
            <div className='pages-header' ref={headerRef}>
                <div className='pages-back-button text-label-style' onClick={handleClose} ref={backRef}><SendArrow />{device === 'pc' ? 'BACK TO VARCHAT' : ''}</div>
                <div className='title' style={{ justifyContent: 'center', width: width }}><svg className='modal-icon' width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 17h2v-6h-2v6zm1-8c.283 0 .52-.096.713-.287A.967.967 0 0 0 13 8a.967.967 0 0 0-.287-.713A.968.968 0 0 0 12 7a.968.968 0 0 0-.713.287A.967.967 0 0 0 11 8c0 .283.096.52.287.713.192.191.43.287.713.287zm0 13a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22zm0-2c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20z" fill="#344D66" />
                </svg>
                    About VarChat</div>
            </div>
            <div className='pages-body'>
                <p style={{ fontSize: '20px' }}><b>VarChat is an open platform that leverages the power of generative artificial intelligence to support the genomic variant interpretation process by searching and condensing the scientific literature available for each variant into a brief yet very infomative text.</b></p>
                <ul className='no-style-list'>
                    <li className='mb-3 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        Get insights from pertinent scientific literature in seconds
                    </li>
                    <li className='mb-3 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        Stay up-to-date with the latest research on genomic variants
                    </li>
                    <li className='mb-4 li-with-icon'>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#s3y7x1cx5a)"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#5ABEBA" /></g><defs><clipPath id="s3y7x1cx5a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>
                        Save a lot of time while receiving accurate and detailed genomic information
                    </li>
                </ul>
                <hr />
                <ul className='mt-4 mb-4'>
                    <li>
                        VarChat is a generative AI-based system and each answer is generated live, so you may obtain slightly different answers at each iteration.
                    </li>
                    <li>
                        A literature search will be performed and the total number of identified publications will be shown. Only a subset of them will be reported and used to generate your answer.
                    </li>
                    <li>
                        Currently, you’ll not be able to continue the conversation on the variant you searched: this feature will be available soon!
                    </li>
                    <li>
                        To unlock new features and stay updated with the latest developments, register on our platform and subscribe to our newsletter.
                    </li>
                    <li>
                        If you want to know more about VarChat and how it works, check out our <a className='link-green' href='https://doi.org/10.1093/bioinformatics/btae183' target='_blank' rel="noreferrer">paper by De Paoli. et al, on Bioinformatics</a> or contact us at <a className='link-green' href='mailto:varchat@engenome.com' target='_blank' rel="noreferrer">varchat@engenome.com</a>
                    </li>
                </ul>
                <hr />
                <div className='mt-4'>
                    <p style={{fontSize:'18px'}} className='mb-2'><b>VarChat software is powered by enGenome </b></p>
                    enGenome, an accredited spin-off from the University of Pavia founded in 2016, combines bioinformatics, biotechnology, and software development expertise to enhance genetic disease diagnosis and treatment through advanced AI and bioinformatics tools, supported by a multidisciplinary team of engineers, biotechnologists, and developers.
                </div>
                <Button className='secondary-button mt-4' style={{ padding: '17px 48px', width: device === 'phone' ? '100%' : 'auto' }} href='https://www.engenome.com/' target='_blank' rel='noreferrer'>ENGENOME WEBSITE</Button>
            </div>
        </div>
    );
}

export default InfoPage;