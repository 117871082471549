import { useDispatch } from "react-redux";
import { useState } from "react";
import { setNewsletterShow, setShowHelpCenter, setShowLogin, setShowSignUp } from "../../slice/ModalVisibilitySlice";
import ReadMore from "./ReadMore";
import ExampleList from "./ExampleList";

function WelcomeMessage() {
    const dispatch = useDispatch();
    const [expand, setExpand] = useState(false);

    return (
        <div>
            <ExampleList type='welcome'/>
            <div className='mb-2'>Click on the examples and search to obtain a summary of the relevant literature. <br />
            </div>
            <div className='mb-2'>
                <b>What's new:</b>
                <ul className="mt-1">
                    <li>
                        New input accepted: you can now query by transcript, genomic coordinates, or simply type the gene symbol to get a summary of the relevant information.
                    </li>
                    <li>
                        Boosted summarization capabilities exploiting information from the full text.
                    </li>
                    <li>
                        Redesigned GUI to enhance and simplify your VarChat experience.
                    </li>
                    <li>
                        For more details, visit the <span className='message-link' onClick={() => { dispatch(setShowHelpCenter(true)) }}>Help Center</span>.
                    </li>
                </ul>
                <div>Check out our <a className='link-generic' href='https://doi.org/10.1093/bioinformatics/btae183' target='_blank' rel="noreferrer">paper by De Paoli. et al, on Bioinformatics</a>.</div>
            </div>
            <div className={expand ? "section-exp" : 'section-compact'}>
                <div className='mb-2'> Eager to discover VarChat's upcoming developments and exciting new features?<br />Subscribe to the VarChat <span className='message-link' onClick={() => { dispatch(setNewsletterShow(true)) }}>newsletter</span>!</div>
                <div className="mb-2">Did VarChat help you? Was the answer accurate? What more could VarChat do for you? Let us know what you think! Star ratings and comments can be provided for each answer.</div>
                <div className="mb-2"><span className='message-link' onClick={() => { dispatch(setShowLogin({ show: true, type: 'logButton' })) }}>Log In</span> to translate your answer in more than 30 different languages!
                    <svg name='translate-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="language"><path d="M4 25a1 1 0 0 1-1-1V5a2.002 2.002 0 0 1 2-2h18a2.002 2.002 0 0 1 2 2v14a2.002 2.002 0 0 1-2 2H8.414l-3.707 3.707A.999.999 0 0 1 4 25zM23 4.998 5 5v16.586l2.293-2.293A1 1 0 0 1 8 19h15zM44 45a.999.999 0 0 1-.707-.293L39.586 41H25a2.002 2.002 0 0 1-2-2V25a2.002 2.002 0 0 1 2-2h18a2.002 2.002 0 0 1 2 2v19a1 1 0 0 1-1 1zM25 25v14h15a1 1 0 0 1 .707.293L43 41.586V25z"></path><path d="M10.998 17a1 1 0 0 1-.14-1.99 6.125 6.125 0 0 0 3.435-1.717A6.057 6.057 0 0 0 15.73 11H11a1 1 0 0 1 0-2h6a1 1 0 0 1 .99 1.141 8.07 8.07 0 0 1-6.848 6.849 1.008 1.008 0 0 1-.143.01Z"></path><path d="M14 11a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v2a1 1 0 0 1-1 1zm4.001 6q-.039 0-.08-.003a7.522 7.522 0 0 1-5.764-3.459 1 1 0 0 1 1.686-1.076 5.516 5.516 0 0 0 4.235 2.54A1 1 0 0 1 18.001 17zm11.998 20a1 1 0 0 1-.893-1.447l4-8a1 1 0 0 1 1.788.894l-4 8A1 1 0 0 1 30 37z"></path><path d="M38.001 37a1 1 0 0 1-.895-.553l-4-8a1 1 0 0 1 1.788-.894l4 8A1 1 0 0 1 38.002 37Z"></path><path d="M37.483 33.967h-6.966a1 1 0 0 1 0-2h6.966a1 1 0 0 1 0 2zM10 27a1 1 0 0 1-.707-1.707l2-2a1 1 0 0 1 1.414 1.414l-2 2A.997.997 0 0 1 10 27z"></path><path d="M14 27a.997.997 0 0 1-.707-.293l-2-2a1 1 0 0 1 1.414-1.414l2 2A1 1 0 0 1 14 27Z"></path><path d="M20 35h-7a2.002 2.002 0 0 1-2-2v-9a1 1 0 0 1 2 0v9h7a1 1 0 0 1 0 2zm16-14a1 1 0 0 1-.707-1.707l2-2a1 1 0 0 1 1.414 1.414l-2 2A.997.997 0 0 1 36 21z"></path><path d="M36 21a.997.997 0 0 1-.707-.293l-2-2a1 1 0 0 1 1.414-1.414l2 2A1 1 0 0 1 36 21Z"></path><path d="M36 21a1 1 0 0 1-1-1v-9h-7a1 1 0 0 1 0-2h7a2.002 2.002 0 0 1 2 2v9a1 1 0 0 1-1 1Z"></path></svg>
                    , or <span className='message-link' onClick={() => { dispatch(setShowSignUp(true)) }}>Sign Up</span> for free.</div>
            </div>
            <ReadMore expand={expand} setExpand={setExpand} />
        </div>
    );
}

export default WelcomeMessage;