import { Button, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setShowDeleteAccount } from '../../../slice/ModalVisibilitySlice';
import DeleteAccountModal from './DeleteAccountModal';

function RemoveAccount() {
    const dispatch = useDispatch();
    
    return (
        <>
            <Row className='mt-4'>
                <div className='profile-label'>Delete account</div>
                <span className='delete-account'>
                    <p className='mb-0'>Deleting your account will remove all of your information from our database.</p>
                    <Button className='delete-button mb-0 red-button small display-align-center' onClick={() => { dispatch(setShowDeleteAccount(true)) }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 21c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 5 19V6H4V4h5V3h6v1h5v2h-1v13c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 17 21H7zM17 6H7v13h10V6zM9 17h2V8H9v9zm4 0h2V8h-2v9z" fill="#ee8373" />
                    </svg> <span>DELETE ACCOUNT</span></Button>
                </span>
            </Row>
            <DeleteAccountModal />
        </>
    );
}

export default RemoveAccount;