import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectActivateAccount, setActivateAccount } from '../../../slice/ModalVisibilitySlice';
import { useNavigate } from 'react-router-dom';
import { Url } from '../../../constants/global';
import { selectTheme } from '../../../slice/SettingSlice.js';
import { isMobile } from 'react-device-detect';

function ResendActivateAccountLink() {
  const show = useSelector(selectActivateAccount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const username = urlParams.get('username');
  const theme = useSelector(selectTheme);

  const handleClose = () => {
    setActivateAccount(false);
    navigate("/");
  };

  dispatch(setActivateAccount(true)); //set visibility true

  /* function sendMail() {
    const fetchPromise = fetch(Url + '/varchat/api/auth/resend-confirmation-email?' + new URLSearchParams({ username: username }), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    fetchPromise
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        } else {
          handleClose();
        }
      })
      .catch((error) => {
        console.error(`Could not get products: ${error}`);
      });
  } */

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false}>
        <Modal.Header>
          <Modal.Title className='title'>
            <svg className='modal-icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 20c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4h16c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v3.975h-2V8l-8 5-8-5v10h12v2H4zm8-9 8-5H4l8 5zm7 9a.968.968 0 0 1-.712-.288A.968.968 0 0 1 18 19v-3c0-.283.096-.52.288-.713A.968.968 0 0 1 19 15v-1c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 21 12c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v1c.283 0 .52.096.712.287.192.192.288.43.288.713v3c0 .283-.096.52-.288.712A.968.968 0 0 1 23 20h-4zm1-5h2v-1a.968.968 0 0 0-.288-.713A.968.968 0 0 0 21 13a.968.968 0 0 0-.712.287A.968.968 0 0 0 20 14v1z" fill="#5ABEBA" />
            </svg>
            Activate your account</Modal.Title>
          <Button onClick={handleClose} id='close' className='close-button'>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <div className='center' style={{ fontWeight: '500' }}>Your activation link has expired. Please sign up again</div>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResendActivateAccountLink;