import NewsletterModal from "./NewsletterSubscription/NewsletterModal";
import Login from "./RegistrationLogin/Login";
import SignUp from "./RegistrationLogin/SignUp";

//serve per appendere al dom le modal del side menu, perché alcune possono essere aperte anche quando il loro button non è presente (dal welcome message per es)
function SideMenuModals(){
    return(
        <>
            <Login/>
            <SignUp/>
            <NewsletterModal />
        </>
    );
}

export default SideMenuModals;