import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import SwitchTheme from '../SwitchTheme';
import PolicyButton from '../PrivacyPolicy/PrivacyButton';
import TermsButton from '../TermsOfUse/TermsButton';

function SideMenuFooter() {
    return (
        <>
            <Row className='mb-4'>
                <Col aria-colspan={2} className='display-align-center'><SwitchTheme/></Col>
            </Row>
            <Row>
                <Col style={{borderRight:'2px solid var(--blue-90)'}}><PolicyButton/> </Col>
                <Col><TermsButton/></Col>
            </Row>
        </>
    );
}

export default SideMenuFooter;