import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function TooltipUser() {
  const renderTooltipUser = (props) => (
    <Tooltip className='tool-no-wrap' id="tooltip" {...props}>
      <div className='tool'>
        Username must contain alphanumerical characters only
      </div>
    </Tooltip>);

  return (
    <OverlayTrigger  placement="bottom" delay={{ show: 100, hide: 150 }} overlay={renderTooltipUser}>
      <svg className='tool-icon' width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h18v18H0z" />
          <path d="M8.25 5.25h1.5v1.5h-1.5v-1.5zm0 3h1.5v4.5h-1.5v-4.5zM9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zM9 15c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 3.307-2.693 6-6 6z" fill="#344D66" />
        </g>
      </svg>
    </OverlayTrigger>
  );
}

export default TooltipUser;