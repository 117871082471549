import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { selectInputMessage, selectLineBreak, selectSend, selectUrlText, setInputMessage, setLineBreak, setSend, setUrlText } from '../../slice/InputMessageSlice';
import { deleteMessage, selectMessages, setMessage, updateMessage, updateMessageText } from '../../slice/MessageSlice';
import { useEffect, useRef, useState } from 'react';
import { sendMessageGA } from '../../GoogleAnalytics4/GoogleAnalyticsEvents';
import { selectStreaming, setShowLoader, setStreaming, selectTranslating, selectShowLoader } from '../../slice/loaderSlice';
import { Url } from '../../constants/global';
import { useCacheBuster } from "react-cache-buster";
import { selectBearer, selectController, setController } from '../../slice/bearerSlice';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { selectUserData } from '../../slice/userDataSlice';
import SendArrow from '../../Images/SendArrow';
import { selectRequest, setRequest } from '../../slice/userActionsSlice';
import { setShowLogin } from '../../slice/ModalVisibilitySlice';
import { validateVariant } from './validateFunction';

function SendMessageButton() {
    const inputMessage = useSelector(selectInputMessage);
    const messages = useSelector(selectMessages);
    const send = useSelector(selectSend);
    const buttonRef = useRef(null);
    const dispatch = useDispatch();
    const streaming = useSelector(selectStreaming);
    const translating = useSelector(selectTranslating);
    const loader = useSelector(selectShowLoader);
    const [disable, setDisable] = useState(false);
    const reload = useRef(true);
    const text = useSelector(selectUrlText);
    const linebreak = useSelector(selectLineBreak);
    const urlParams = new URLSearchParams(window.location.search);
    const { checkCacheStatus } = useCacheBuster();
    const controller = useSelector(selectController);
    const signal = controller.signal;
    const user = useSelector(selectUserData).user;
    const [stopSearch, setStopSearch] = useState(false);
    const nRequest = useSelector(selectRequest);
    const logToken = useSelector(selectBearer);

    useEffect(() => {
        if (window.location.pathname === '/search' && urlParams.get('text') !== null && text !== urlParams.get('text')) {
            dispatch(setUrlText(urlParams.get('text')));
            dispatch(setInputMessage(urlParams.get('text')));
            dispatch(setSend(!send));
            dispatch(setLineBreak(true));
        } else if (!reload.current || send === "send") {
            console.log('send', reload.current, send, send === "send")
            buttonRef.current.click();
        }
        reload.current = false;
    }, [send])

    useEffect(() => {
        if (translating) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [translating])

    useEffect(() => {
        if (stopSearch) {
            setStopSearch(false);
            dispatch(setStreaming(false));
        }
    }, [stopSearch])

    function clickSendButton() {
        if (streaming) {
            stopSearching();
        } else {
            sendMessage();
        }
    }

    function stopSearching() {
        controller.abort();
        dispatch(setController(new AbortController()));
        if (loader) {
            dispatch(updateMessage({ i: messages.length - 1, obj: { stopText: 'Variant search has been interrupted by the user.', text: '' } }))
            dispatch(setShowLoader(false));
        } else {
            dispatch(updateMessage({ i: messages.length - 1, obj: { stopText: '\nAnswer generation has been interrupted by the user.' } }))
        }
        setStopSearch(true);
    }

    async function sendMessage() {
        if (inputMessage.trim(' ') !== '') {
            dispatch(setRequest(nRequest + 1));
            if (logToken === '' && nRequest === 1) {
                dispatch(setShowLogin({ show: true, type: 'automatic' }));
            }
            checkCacheStatus();
            sendMessageGA();
            const validation = validateVariant(inputMessage);
            const text = validation.text;
            dispatch(setMessage({ text: inputMessage, user: 'You', linebreak: linebreak }));
            dispatch(setInputMessage(''));
            dispatch(setStreaming(true));
            dispatch(setLineBreak(false));
            if (validation.val) {
                const filteredParams = [text.rs, text.hgvs_c, text.hgvs_p, text.genomic_coord, text.hgvs_m].filter((str) => str !== '');
                let loading_message = '';
                if(filteredParams.length !== 0){
                    loading_message = 'Searching for variant ' + filteredParams.join(', ') + (text.transcript !== '' ? ' on transcript ' + text.transcript : '') + (text.gene !== "" ? ' on gene ' + text.gene : "");
                } else {
                    loading_message = 'Searching for gene ' + text.gene;
                }
                dispatch(setMessage({ text: loading_message, user: 'VarChat' }))
                try {
                    const param = Object.assign({}, text, { stream: true, user: user, userInput: inputMessage.trim() });
                    await streamSummary(param, text);
                } catch (error) {
                    console.error(`Could not get products: ${error}`);
                }
            } else {
                dispatch(setMessage({ text: 'Searching ' + inputMessage, user: 'VarChat' }));
                try {
                    const param = { gene: '', rs: '', hgvs_c: '', hgvs_p: '', hgvs_m: '', transcript: '', genomic_coord: '', stream: true, user: user, userInput: inputMessage.trim() };
                    await sendVariantValidateFalse(param, text);
                } catch (error) {
                    console.error(`Could not get products: ${error}`);
                }
                setTimeout(() => {
                    dispatch(deleteMessage());
                    dispatch(setStreaming(false));
                    dispatch(setMessage({ text: text, user: 'VarChat', statusCode: validation.status }));
                }, 2000)
            }
        }
    }

    async function sendVariantValidateFalse(param, text) {
        await fetchEventSource(Url + '/varchat/api/request/?' + new URLSearchParams(param), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            openWhenHidden: true,
            signal: signal,
            onopen(response) {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    return;
                }
            },
            onclose() {
                console.log('close1');
            },
            onerror(error) {
                throw error;
            }
        });
    }

    async function streamSummary(param, text) {
        let jsonData = {};
        await fetchEventSource(Url + '/varchat/api/request/?' + new URLSearchParams(param), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            openWhenHidden: true,
            signal: signal,
            onopen(response) {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    return;
                }
            },
            onmessage(msg) {
                try {
                    const data = JSON.parse(msg.data);
                    console.log(data);
                    if (Object.keys(jsonData).length === 0) {
                        console.log('initial data', JSON.parse(msg.data))
                        dispatch(deleteMessage());
                        dispatch(setShowLoader(false));
                        dispatch(setMessage({
                            text: "",
                            user: 'VarChat',
                            userInput: text,
                            statusCode: data.status_answer_code,
                            language: 'EN',
                            statusInfoCode: data.status_info_code
                        }));
                        jsonData = {
                            evaluate: true,
                            cits: data.cits,
                            citCount: data.cit_count,
                            rcvCitCount: data.rcv_cit_count,
                            rcvCits: data.rcv_cits,
                        };
                    } else {
                        if (data.status_answer_code !== undefined) {
                            jsonData = Object.assign(jsonData, { statusCode: data.status_answer_code });
                            // console.log('dati', jsonData, 'dataa', data);
                        } else {
                            dispatch(updateMessageText({ i: messages.length + 1, text: data }));
                            // console.log('status answer undefined', data)
                        }
                    }
                } catch {
                    const word = msg.data === '' ? '\n' : msg.data;
                    // console.log('data word', msg.data, 'word', word)
                    dispatch(updateMessageText({ i: messages.length + 1, text: word }));
                }
            },
            onclose() {
                dispatch(updateMessage({ i: messages.length + 1, obj: jsonData }))
                //                dispatch(setStreaming(false)); moved to use effect of messages (for scrolling purposes)
                console.log('close');
            },
            onerror(error) {
                dispatch(setStreaming(false));
                dispatch(deleteMessage());
                dispatch(setMessage({ user: 'VarChat', statusCode: 5 }));
                throw error;
            }
        });
    }

    return (
        <div name='send-button-div'>
            <span>{!streaming ? 'SEND' : 'STOP'}</span>
            <Button name='send-button' onClick={clickSendButton} ref={buttonRef} disabled={disable}>
                {streaming ? <FontAwesomeIcon icon={faStop} /> : <SendArrow />}
            </Button>
        </div>
    );
}

export default SendMessageButton;