import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectTheme } from '../../../slice/SettingSlice';
import { selectMessages } from '../../../slice/MessageSlice';
import { selectUserData } from '../../../slice/userDataSlice';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { sendCommentGA } from '../../../GoogleAnalytics4/GoogleAnalyticsEvents';
import { Url } from '../../../constants/global';
import { isMobile } from 'react-device-detect';
import { setShowAddComment } from '../../../slice/ModalVisibilitySlice';
import FormNewsletter from "../../SideMenu/NewsletterSubscription/FormNewsletter";
import { selectBearer } from '../../../slice/bearerSlice';

function AddCommentModal(props) {
    const show = props.addComment.showAddComment;
    const theme = useSelector(selectTheme);
    const [comment, setNewComment] = useState('');
    const message = useSelector(selectMessages)[props.ind];
    const [sendFeed, setSendFeed] = useState(false);
    const logToken = useSelector(selectBearer);
    const email = useSelector(selectUserData).email || null;
    const dispatch = useDispatch();

    const handleClose = () => {
        if(!sendFeed){
            sendFeedBack();
        }
        props.addComment.setShowAddComment2(false);
        dispatch(setShowAddComment(false));
    };

    function handleSubmit(event) {
        event.preventDefault();
        sendCommentGA();
        sendFeedBack();
        setSendFeed(true);
    }

    function sendFeedBack() {
        let answer = message.text;
        if (message.language !== 'EN') {
            answer = answer.concat('\n\n' + message.translation[message.language]);
        }
        const fetchPromise = fetch(Url + '/varchat/api/save-feedback/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, userinput: message.userInput, rating: message.rate, comment: comment, response: { answer: answer, cits: message.cits }, lang: message.language })
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                }
            })
            .catch((error) => {
                console.error(`Could not get products: ${error}`);
            });
    }

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false} onExited={() => { setSendFeed(false) }}>
            <Modal.Header>
                <Modal.Title className='title'>
                <svg width="24" height="24" className='modal-icon' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m6 18-2.3 2.3c-.317.317-.68.387-1.088.212-.408-.175-.612-.487-.612-.937V4c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 4 2h16c.55 0 1.02.196 1.413.587C21.803 2.98 22 3.45 22 4v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 18H6zm-.85-2H20V4H4v13.125L5.15 16zM7 14h6c.283 0 .52-.096.713-.287A.968.968 0 0 0 14 13a.968.968 0 0 0-.287-.713A.968.968 0 0 0 13 12H7a.967.967 0 0 0-.713.287A.968.968 0 0 0 6 13c0 .283.096.52.287.713.192.191.43.287.713.287zm0-3h10c.283 0 .52-.096.712-.287A.968.968 0 0 0 18 10a.967.967 0 0 0-.288-.713A.968.968 0 0 0 17 9H7a.968.968 0 0 0-.713.287A.968.968 0 0 0 6 10c0 .283.096.52.287.713.192.191.43.287.713.287zm0-3h10c.283 0 .52-.096.712-.287A.967.967 0 0 0 18 7a.967.967 0 0 0-.288-.713A.968.968 0 0 0 17 6H7a.968.968 0 0 0-.713.287A.968.968 0 0 0 6 7c0 .283.096.52.287.713.192.191.43.287.713.287z" fill="#5ABEBA"/></svg>
                    Evaluate the answer</Modal.Title>
                <Button onClick={handleClose} id='close' className='close-button'>
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                </Button>
            </Modal.Header>
            <Modal.Body>
                {sendFeed ? <><div className='center' style={{fontWeight:'500'}}>Thank you for your feedback!</div> {logToken === '' ? <FormNewsletter stars={true} /> : null}</>
                 :
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <div name='modal-star'><span>{props.stars}</span></div>
                            <Form.Label className='mb-3'><p className='p-label-new'>Click 'send' to record your feedback. Thanks!</p></Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder='Add a comment (optional).' onChange={(e) => { setNewComment(e.target.value) }} maxLength='1000' />
                        </Form.Group>
                        <span className='display-align-center' style={{width: '100%'}}>
                            <Button className="primary-button" type='submit'>
                                SEND
                            </Button>
                        </span>
                    </Form>
                }
            </Modal.Body>
        </Modal>
    );
}

export default AddCommentModal;