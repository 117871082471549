import { useState } from 'react';
import Form from 'react-bootstrap/Form';

function SelectInterest(props) {
    const { setPromptFields } = props;
    const [selectValue, setSelectValue] = useState('');

    function onSelectInterest(e) {
        setSelectValue(e.target.value);
        setPromptFields((state) => { return { ...state, interest: e.target.value } });
    }

    return (
        <><Form.Select id="interest" name="interest" value={selectValue} onChange={(e) => { onSelectInterest(e) }}>
            <option value='' disabled defaultValue={true}>your interest</option>
            <option value='Cancer Genetics'>Cancer Genetics</option>
            <option value='Clinical Genetic Testing'>Clinical Genetic Testing</option>
            <option value='Clinical Genetics/Dysmorphology'>Clinical Genetics/Dysmorphology</option>
            <option value='Complex Traits'>Complex Traits</option>
            <option value='Education, Public Services, Legal Issues'>Education, Public Services, Legal Issues</option>
            <option value='Epigenetics'>Epigenetics</option>
            <option value='Functional Genomics '>Functional Genomics </option>
            <option value='Genetic Counselling, Psychosocial Aspects'>Genetic Counselling, Psychosocial Aspects</option>
            <option value='Genome Structure, Variation and Function'>Genome Structure, Variation and Function</option>
            <option value='Omics/Bioinformatics'>Omics/Bioinformatics</option>
            <option value='Personalised/Predictive Medicine'>Personalised/Predictive Medicine</option>
            <option value='Pharmacogenetics'>Pharmacogenetics</option>
            <option value='Statistical Genetics and Genetic Epidemiology'>Statistical Genetics and Genetic Epidemiology</option>
            <option value='Other'>Other</option>
        </Form.Select>{selectValue==='Other' && <input type='text' className='prompt-engineering-input mt-3' placeholder='Your interest' onChange={(e) => {setPromptFields((state) => { return { ...state, interest: e.target.value } })}}></input>}</>
    );
}

export default SelectInterest;