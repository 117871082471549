import Form from "react-bootstrap/Form";
import { Button, Col } from "react-bootstrap";
import { Url } from "../../../constants/global";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNewsLetterShow } from "../../../slice/ModalVisibilitySlice";
import robot from '../../../Images/varchat-icon-robot-2.svg';
import robotDark from '../../../Images/varchat-icon-robot-dark.svg';
import { selectTheme } from "../../../slice/SettingSlice";
import { setPrivacy } from "../../../slice/ModalVisibilitySlice";

function FormNewsletter(props) {
    const [email, setEmail] = useState("");
    const [errors, setShowErrors] = useState(false);
    const [errorType, setErrorType] = useState("invalid");
    const { stars } = props;
    const [disabled, setDisabled] = useState(false);
    const newsLetterShow = useSelector(selectNewsLetterShow);
    const [validated, setValidated] = useState(false);
    const [sent, setSent] = useState(false);
    const theme = useSelector(selectTheme);
    const robotIcon = theme === 'light' ? robot : robotDark;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!newsLetterShow) {
            setEmail('');
            setValidated(false);
            setSent(false);
            setShowErrors(false);
            setErrorType("invalid");
        }
    }, [newsLetterShow]);

    useEffect(() => {
        if (disabled) { /*it means it's time to send and wait!*/
            sendEmailNewsletter();
        }
    }, [disabled])

    function handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            setDisabled(true);
        }
        setValidated(true);
    }

    function onChangeEmail(e) {
        setShowErrors(false);
        setErrorType('invalid');
        setEmail(e.target.value);
        if (e.currentTarget.checkValidity()) {
            setValidated(false);
        }
    }

    function sendEmailNewsletter() {
        const fetchPromise = fetch(Url + '/varchat/api/newsletter/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email })
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    return response.text().then(text => {
                        throw new Error(text)
                    })
                }
                setSent(true);
                setDisabled(false);
            })
            .catch((error) => {
                try {
                    const errorMess = JSON.parse(error.message)
                    const stringBody = errorMess['detail'].replace(/'/g, '"');
                    const body = (JSON.parse(stringBody))
                    if (body.title === 'Invalid Resource') {
                        /*what to do if email invalid from mailchimp?*/
                        setShowErrors(true);
                        setErrorType('invalid');
                    } else if (body.title === 'Member Exists') {
                        /*person already present in our mailchimp, what to do?*/
                        setShowErrors(true);
                        setErrorType('exists');
                    } else {
                        //generic error
                        setShowErrors(true);
                        setErrorType('error');
                    }
                } catch (e) {//generic error, if the response is not a json
                    setShowErrors(true);
                    setErrorType('error');
                }
                setDisabled(false);
            });
    }

    return (<>{!sent ? <><div className='center mb-3'>{stars ? <>Want to know more about VarChat's upcoming developments? Subscribe to the VarChat newsletter now!</>
        : <><img src={robotIcon} alt='varChat' className="mb-4" style={{ height: '88px' }} /> <div>Eager to discover VarChat's upcoming developments and exciting new features? Don't miss out!</div></>
    }</div>
        <Form noValidate className={'newsletter-form' + (stars ? ' stars' : '')} validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationEmail">
                <label>EMAIL</label>
                <Form.Control value={email} required type='email' placeholder='example@email.com' onChange={(e) => onChangeEmail(e)} />
                <Form.Control.Feedback className={'invalid' + (errors ? ' feedback-show' : '')} type="invalid">
                    {errorType === 'invalid' ? 'Please input a correct email address.' : errorType === 'exists' ? 'Email already subscribed!' : 'An error occured.'}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Col className="myCheckbox mb-3">
                    <input type="checkbox" name="check2" id="checkbox_newsletter" required />
                    <span></span>
                    <label htmlFor="checkbox_newsletter" className='check lab'>I declare that I have read the <span onClick={() => { dispatch(setPrivacy(true)) }}>Privacy Policy</span></label>
                    <Form.Control.Feedback type="invalid" className='invalid'>Please accept our Privacy Policy.</Form.Control.Feedback>
                </Col>
            </Form.Group>
            <span className='span-align-right'>
                <Button disabled={disabled} className="primary-button" type='submit'>SUBSCRIBE</Button>
            </span>
        </Form></> : <div className='center' style={{fontWeight:'500'}}>Thanks for subscribing to the VarChat newsletter!<br />Stay tuned for exciting updates!</div>}</>);
}

export default FormNewsletter;