import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import AppContainer from './main/AppContainer';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
// import '../node_modules/font-awesome/css/font-awesome.min.css';
import { PersistGate } from 'redux-persist/integration/react';
import {GTM_ID} from './constants/global';
import TagManager from 'react-gtm-module';
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from './main/ErrorPage';
import ResetPassword from './components/SideMenu/RegistrationLogin/ResetPassword';
import ResendActivateAccountLink from './components/SideMenu/RegistrationLogin/ResendActivateAccountLink';

const tagManagerArgs = {
  gtmId: GTM_ID
}
TagManager.initialize(tagManagerArgs)

const container = document.getElementById('root');
const root = createRoot(container);

const router = createBrowserRouter([
    {
      path: "/",
      element: <AppContainer />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "search",
        },
        {
          path: "resetPassword",
          element: <ResetPassword />,
        },
        {
          path: "firstLogin",
        },
        {
          path: "activateAccount",
          element: <ResendActivateAccountLink/>
        }
      ]
    },
  ]);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <RouterProvider router={router} />
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
