import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { selectProfile, setShowProfile } from '../../../slice/ModalVisibilitySlice.js';
import { selectUserData } from '../../../slice/userDataSlice.js';
import { useEffect, useState, useRef } from 'react';
import { setPasswordInit } from '../../../slice/passwordSlice.js';
import { setMessageProfileVal, selectMessageProfileVal, setMessageProfileValInit } from '../../../slice/messageProfileValidationSlice.js';
import './Profile.css';
import ChangePersonalInfo from './ChangePersonalInfo.js';
import ChangePassword from './ChangePassword.js';
import { selectLanguage, setLanguage } from '../../../slice/SettingSlice.js';
import { selectDevice } from '../../../slice/deviceSlice.js';
import { selectBearer } from '../../../slice/bearerSlice.js';
import RemoveAccount from './RemoveAccount.js';
import SendArrow from '../../../Images/SendArrow';
import AccountIcon from '../../../Images/AccountIcon.js';

function ProfilePage() {
	const show = useSelector(selectProfile).show;
	const dispatch = useDispatch();
	const initialVal = { personalInfo: false, password: false };
	const [validated, setValidated] = useState(initialVal);
	const data = useSelector(selectUserData);
	const [edit, setEdit] = useState({ firstname: "", lastname: "", user: "", email: "", aff: "" });
	const [country1, setCountry1] = useState(data.country);
	const language = useSelector(selectLanguage);
	const [changeLang, setChangeLang] = useState(language);
	const messageValidation = useSelector(selectMessageProfileVal);
	const device = useSelector(selectDevice);
	const [width, setWidth] = useState(0);
	const backRef = useRef(null);
	const headerRef = useRef(null);
	const padding = device === 'pc' ? 48 : 32; //somma dei padding sx e dx
	const logToken = useSelector(selectBearer);

	
	useEffect(() => { //to close the page in case of logout in another tab (to be set in all modals and pages that are dispalyed only after authentication)
		if (logToken === '' && show) {
			handleClose();
		}
	})

	useEffect(() => { //to call handleClose when the page is closed using sidemenu buttons
		if (!show) {
			handleClose();
		}
	}, [show])

	useEffect(() => {
		setWidth(headerRef.current.offsetWidth - (backRef.current.offsetWidth * 2) - padding);
	});

	const handleClose = () => {
		dispatch(setShowProfile({show:false, type: 'profileButton'}));
		dispatch(setPasswordInit());
		setEdit({ firstname: "", lastname: "", user: "", aff: "" });
		setCountry1(data.country); //per non far visualizzare il reset mentre si chiude la modal
		dispatch(setLanguage(changeLang));
		dispatch(setMessageProfileValInit());
		setValidated(initialVal);
	};

	function handleChange(id) {
		dispatch(setMessageProfileVal(messageValidation.map((err) => {
			if (err.id === id) {
				return { ...err, show: false, value: '', type: '' };
			} else {
				return { ...err };
			}
		})));
	}

	return (
		<div style={show ? {height:'100%'} : {display: 'none'}}>
			<div className='pages-header' ref={headerRef}>
				<div className='pages-back-button text-label-style' onClick={handleClose} ref={backRef}><SendArrow />{device === 'pc' ? 'BACK TO VARCHAT' : ''}</div>
				<div className='title' style={{ justifyContent: 'center', width: width }}>
					<AccountIcon />
					My profile</div>
			</div>
			<div className='pages-body'>
				<ChangePersonalInfo handleChange={handleChange} edit={[edit, setEdit]} val={[validated, setValidated]} country={[country1, setCountry1]} lang={[changeLang, setChangeLang]} handleClose={handleClose} />
				<hr />
				<ChangePassword val={[validated, setValidated]} handleClose={handleClose} />
				<hr />
				<RemoveAccount />
			</div>
		</div>
	);
}

export default ProfilePage;