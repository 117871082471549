import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectInfo, setInfo, setShowHelpCenter, setShowProfile } from '../../../slice/ModalVisibilitySlice';
import { setShowSide } from '../../../slice/SideMenuSlice';

function InfoButton() {
    const dispatch = useDispatch();
    const showInfo = useSelector(selectInfo);

    function openInfo(e) {
        e.stopPropagation();
        dispatch(setShowSide(false)); //to close side menu in phone
        dispatch(setInfo(!showInfo));
        dispatch(setShowProfile({show:false, type: 'profileButton'}));
        dispatch(setShowHelpCenter(false));
    }

    return (
        <>
            <Button onClick={(e) => { openInfo(e) }} className={'side-menu-buttons '+ (showInfo ? ' active' : '')}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 17h2v-6h-2v6zm1-8c.283 0 .52-.096.713-.287A.967.967 0 0 0 13 8a.967.967 0 0 0-.287-.713A.968.968 0 0 0 12 7a.968.968 0 0 0-.713.287A.967.967 0 0 0 11 8c0 .283.096.52.287.713.192.191.43.287.713.287zm0 13a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22zm0-2c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20z" fill="#344D66" />
                </svg>
                ABOUT VARCHAT
            </Button>
        </>
    );
}

export default InfoButton;