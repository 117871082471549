import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { selectShowDeleteAccount, setShowDeleteAccount } from '../../../slice/ModalVisibilitySlice';
import { selectTheme } from '../../../slice/SettingSlice.js';
import { isMobile } from 'react-device-detect';
import { selectMessageProfileVal, setMessageProfileVal } from '../../../slice/messageProfileValidationSlice.js';
import { selectBearer, setDeleteAccount, setExpired } from '../../../slice/bearerSlice.js';
import { Url } from '../../../constants/global.js';
import { useState, useEffect } from 'react';

function DeleteAccountModal() {
	const show = useSelector(selectShowDeleteAccount);
	const dispatch = useDispatch();
	const theme = useSelector(selectTheme);
    const messageValidation = useSelector(selectMessageProfileVal);
    const logToken = useSelector(selectBearer);
    const [triggerAfterUpBearer, setTriggerAfterUpBearer] = useState(false);

	useEffect(() => {
        if (triggerAfterUpBearer) { //To trigger the function that had given 401 because the token had expired
            setTriggerAfterUpBearer(false);
            if (logToken !== '') {
                deleteAccount();
            } else {
            	handleClose();
            }
        }
    }, [logToken])

	const handleClose = () => {
		dispatch(setShowDeleteAccount(false));
	};

    function deleteAccount() {
        const fetchPromise = fetch(Url + '/varchat/api/auth/remove-account', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
					if (response.status === 401) {
                        dispatch(setExpired(true));
                        setTriggerAfterUpBearer(true);
                    } else {
						dispatch(setMessageProfileVal(messageValidation.map((err) => {
							if (err.id === 'remove-account') {
								return { ...err, show: true, value: 'An error occurred during deletion', type: 'error' };
							} else {
								return { ...err };
							}
						})));
						throw new Error(`HTTP error: ${response.status}`);
					}
                } else {
                    console.log('cancella account')
                    dispatch(setDeleteAccount(true));
                }
            })
            .catch((error) => {
                console.error(`Could not get products: ${error}`);
            });
    }

	return (
		<>
			<Modal show={show} onHide={handleClose} backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false}>
				<Modal.Header>
					<Modal.Title className='title'>
						<svg className='me-1' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7 21c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 5 19V6H4V4h5V3h6v1h5v2h-1v13c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 17 21H7zM17 6H7v13h10V6zM9 17h2V8H9v9zm4 0h2V8h-2v9z" fill="#ee8373" />
						</svg>
						Delete account</Modal.Title>
					<Button onClick={handleClose} id='close' className='close-button'>
						<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
					</Button>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							<div className='center mb-4' style={{ fontWeight: '500' }}>This process is irreversible. <br /> Are you sure you want to continue?
							</div>
                            <Button className='red-button' onClick={deleteAccount}>CONFIRM</Button>
						</Row>
					</Container>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default DeleteAccountModal;