import { createSlice } from '@reduxjs/toolkit';

const initialState={request: 0}; //count the number of messages sent in this session

export const userActionsSlice = createSlice({
    name: 'useractions',
    initialState,
    reducers: {
        setRequest: (state, action) =>{
            state.request= action.payload;
        }
    }
});

export const { setRequest } = userActionsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectRequest =(state) => state.useractions.request;

export default userActionsSlice.reducer;