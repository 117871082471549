import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage, setLanguage } from '../../../slice/SettingSlice';
import './custom-select.css';
import { useState, useRef } from 'react';
import flagLegend from '../../../constants/flagLegend';
import { selectStreaming, selectTranslating } from '../../../slice/loaderSlice';
import optionsList from './selectLanguageOpt';
import { selectMessageProfileVal, setMessageProfileVal } from '../../../slice/messageProfileValidationSlice';

function SelectLanguage(props) {
    const dispatch = useDispatch();
    const messageValidation = useSelector(selectMessageProfileVal);
    const preferredLanguage = useSelector(selectLanguage);
    const { disabled } = props;
    const streaming = useSelector(selectStreaming);
    const translating = useSelector(selectTranslating);
    const wait = (streaming || translating ? true : false);
    const optionsRef = useRef([]);
    const [showOptions, setShowOptions] = useState(false);
    let options_rows = optionsList.map((op, i) => {
        return <p key={i} className='custom-option' onClick={()=>{optionsKeyDown({key:'Enter'}, i)}} onKeyDown={(e) => { optionsKeyDown(e, i) }} onMouseMoveCapture={(e) => { optionsMouseEvent(e, i) }} ref={el => optionsRef.current[i] = el} tabIndex={0}>
                    <span className={"fi fi-"+ flagLegend[op.value]} title={op.value} name='flag-icon'/>
                    {op.name}
                </p>
    });
    const options = <div className="select-dropdown"> {options_rows}</div>;

    //manage the navigation with keyboard
    function optionsKeyDown(e, i) {
        switch (e.key) {
            case 'Enter':
                dispatch(setLanguage(optionsList[i].value)); 
                setShowOptions(false);
                dispatch(setMessageProfileVal(messageValidation.map((err) => {
                    if (err.id === 'personalInfo') {
                        return { ...err, show: false };
                    } else {
                        return { ...err };
                    }})));
                break;
            case 'ArrowDown':
                if (optionsRef.current.length - 1 > i) {
                    optionsRef.current[i + 1].focus();
                }
                break;
            case 'ArrowUp':
                if (i > 0) {
                    optionsRef.current[i - 1].focus();
                }
                break;
            default:
                focusOption(e);
                break;
        }
    }

    function optionsMouseEvent(e, i) {
        optionsRef.current[i].focus();
    }

    function buttonKeyDown(e){
            switch (e.key) {
                case 'ArrowDown':
                    if ( openSelect ) {
                        optionsRef.current[0].focus();
                    }
                    break;
                default:
                    focusOption(e);
                    break;
            }
        
    }

    function focusOption(e){
        let stop = false;
        optionsList.map((op, i) => {
            if(e.key.toUpperCase() === op.name.substring(0,1) && !stop){
                optionsRef.current[i].focus();
                stop = true;
            }
            return null;
        });
    }

    function openSelect(){
        if(!disabled){
            setShowOptions(!showOptions);
        }
    }

    return (
        <>
            <div className="custom-select">
                <button type="button" className={"custom-select-button" + (showOptions ? ' focus-custom-select' : '') + (wait ? ' custom-select-button-loader' : '')} onClick={() => {openSelect()}} disabled={disabled ? disabled : wait} onKeyDown={(e) => { buttonKeyDown(e) }}> 
                    <span className="selected-value">
                        <span className={"fi fi-"+ flagLegend[preferredLanguage]} title={preferredLanguage} name='flag-icon'/>
                        {optionsList.find((op) => op.value === preferredLanguage).name}
                    </span>
                    <svg className={showOptions ? 'rotate-arrow' : 'no-rotate-arrow'} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
                </button>
                {showOptions ? options : null}
            </div>
        </>

    );
}

export default SelectLanguage;