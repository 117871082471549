import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setPrivacy } from '../../../slice/ModalVisibilitySlice';
import PrivacyPolicy from './PrivacyPolicy';
import { setShowSide } from '../../../slice/SideMenuSlice';

function PrivacyButton(){
    const dispatch = useDispatch();

    function openPrivacy(e){
        e.stopPropagation();
        dispatch(setShowSide(false)); //to close side menu in phone
        dispatch(setPrivacy(true));
    }

    return(
        <>
            <Button onClick={(e)=> {openPrivacy(e)}} className='side-menu-footer-buttons'>
                <span style={{whiteSpace: 'nowrap'}}>Privacy Policy</span>
            </Button>
            <PrivacyPolicy/>
        </>
    );
}

export default PrivacyButton;