let Url='' //da importare url dove chiamerò le api (da vedere quando sarà definito)
let GTM_ID=''

if(process.env.REACT_APP_ENV === 'production'){
    Url = 'https://varchat.engenome.com';
    GTM_ID='GTM-T355DHVT';
    // console.log('prod')
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
} else {
    Url = 'https://dev.varchat.engenome.com';
    // Url = 'https://i43lyh6hj5uhcjrnoju6kegboi0jqzwo.lambda-url.eu-west-1.on.aws'; // to test streaming
    GTM_ID='GTM-5Q6339N3';
    console.log('dev')
}

//to create the build
//npm run build:dev  -> dev env
//npm run build:prod  -> prod env

//to start the environment locally
// npx serve -s build 

export {Url, GTM_ID}