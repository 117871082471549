const examples = {welcome:{ exArray:[
  { ex: 'BRAF:p.V600E' },
  { ex: 'NM_032409.3:c.926G>A' },
  { ex: 'GRCh38 17:g.43049192del' },
  { ex: 'BRCA2' }
]}, status_8: { exArray:[
  { ex: 'BBS1:c.1016A>T' },
  { ex: 'MITF p.Arg324del' },
  { ex: 'VHL p.Asn150fs c.449delA' },
  { ex: 'NM_002834.5:c.691C>T' },
  { ex: 'ENST00000321556.5 p.Arg246Ter' },
  { ex: 'MT-ND4: m.11778G>A' }
], text: 'Variants can be queried by HGVS coordinates at the coding and protein levels, along with gene symbols or transcripts (we support both RefSeq “NM” and Ensembl “ENST” IDs). HGVS coordinates at the coding and protein levels are supported.\nWhen using HGVS standard nomenclature, you can use Coding (e.g., “PINK1:c.926G>A”), Protein (e.g., “PINK1:p.G309D”), and both (e.g., “PINK1:p.G309D, c.926G>A”).\nIf you’re interested in mitochondrial variants, you can also use “m.” HGVS nomenclature (e.g., “m.11778G>A”).\nHere are some examples of valid queries:',
  note: 'If the transcript is provided, VarChat will attempt to use this information to guide the literature search.'
},
  status_4:{ exArray:[
  { ex: 'rs1389335279' },
  { ex: 'rs533071750' },
  { ex: 'rs606231451' },
], text: 'Users can query by dbSNP rs ID, as in the following examples:', note: 'A single dbSNP rs ID can be associated with more than one variant. By searching with an rs ID, users accept that they cannot control the specific variation being queried. To refine this search, different query inputs can be used (e.g., HGVS coding, protein by gene or transcript, or genomic coordinates).'},
  status_6:{ exArray:[
  { ex: '37:7:g.140453136A>C' },
  { ex: '37:2:g.179391818_179391823del' },
  { ex: '37:2:g.179391925_179391935delinsTTTTTCTTTCA' },
  { ex: '38:12:g.57762714_57762716dup' },
  { ex: '38:22:g.17188319_17188320insAGCGGGAT' },
  { ex: 'GRCh38 11 77213978 T C' },
  { ex: 'GRCh38 4 79984835 G -' },
  { ex: 'GRCh38 4 79984835 GC G' },
  { ex: 'hg19 chr7 140453136 - CAT' },
  { ex: 'GRCh37 7 140453136 - CAT' },
  { ex: 'GRCh37 11 66114640 GC CT' },

], text: 'Users can query by genomic coordinates. We support both HGVS genomic coordinates (g.) and VCF-like coordinates.\nThe genomic assembly must always be indicated, such as hg19, GRCh37, or GRCh38.\nHere is a list of examples on how to represent the most common variations in a format that VarChat can accept:', 
  note: 'In this format, it is mandatory to specify the assembly. If the specified coordinate is not valid (e.g., the reference nucleotide differs from what’s expected), the request will not be completed.',
  title: 'HGVS Genomic Coordinates and VCF-like Coordinates:'
},
  status_7:{ exArray:[
    {ex: 'BRAF'},
    {ex: 'C9Orf72'},
    {ex: 'ZEB2'},
    {ex: 'PINK1-AS1'}
  ], text: 'With VarChat, you can generate a summary about a gene. Information from different sources (e.g., NCBI, PubMed Central, Google Scholar) will be retrieved and integrated to generate the summary.\nExamples of valid queries are:',
    note: 'VarChat is a versatile system that relies on your input, so be cautious of typos as they might not always be detected. If an invalid gene symbol is provided, the request will not be completed. In this case, try searching for synonym gene symbols (aliases).'
  }
};

export default examples;