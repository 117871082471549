import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './appContainer.css';
import '../components/modal.css';
import '../components/pages.css';
import './issueBanner.css';
import SideMenu from '../components/SideMenu/SideMenu';
import MessageContainer from '../components/MessageContainer/MessageContainer';
import SendMessageContainer from '../components/SendMessageContainer/SendMessageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { selectTheme } from '../slice/SettingSlice';
import { Outlet } from "react-router-dom";
import { selectDevice, setDevice } from '../slice/deviceSlice';
import { isMobile, browserName } from 'react-device-detect';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../../package.json';
import { setController } from '../slice/bearerSlice';
import { selectShowSide } from '../slice/SideMenuSlice';
import { selectInfo, selectProfile, selectShowHelpCenter } from '../slice/ModalVisibilitySlice';
import InfoPage from '../components/SideMenu/InfoBox/InfoPage';
import ProfilePage from '../components/SideMenu/ProfileSettings/ProfilePage';
import LoadingPage from './LoadingPage';
import HelpCenterPage from '../components/SideMenu/HelpCenter/HelpCenterPage';
import { selectFirstRender, setFirstRender } from '../slice/loaderSlice';
let version = packageInfo.version;

function AppContainer() {
    const theme = useSelector(selectTheme);
    const device = useSelector(selectDevice);
    const dispatch = useDispatch();
    const deviceOr = window.matchMedia("(orientation: portrait)");
    const controller = new AbortController(); //controller is used to cancel requests while logging out
    const openMenu = useSelector(selectShowSide);
    const showInfo = useSelector(selectInfo);
    const showProfile = useSelector(selectProfile).show;
    const showHelpCenter = useSelector(selectShowHelpCenter);
    const firstRender = useSelector(selectFirstRender);
    const [showLoader, setShowLoader] = useState(firstRender);

    useEffect(() => {
        dispatch(setController(controller));
        if(firstRender){
            dispatch(setFirstRender(false));
            setTimeout(()=>{
                setShowLoader(false)
            }, 2000)
        }
    }, [])

    deviceOr.onchange = (e) => {
        if (e.matches && isMobile) {
            dispatch(setDevice('phone'));
        } else {
            dispatch(setDevice('pc'));
        }
    }

    return (<CacheBuster currentVersion={version} isEnabled={true} isVerboseMode={false} loadingComponent={<></>}>
        <Container fluid className={'cont ' + theme} style={(!isMobile && browserName === 'Safari' ? { minHeight: '100vh' } : null)}>
            <LoadingPage showLoader={showLoader}/>
            {showLoader ? null :
            <Row style={{ height: '100vh', height: '100dvh'}}>
                <SideMenu colName='side-menu' />
                <Col className={'col-container ' + device} style={openMenu ? { display: 'none' } : null}>
            {/* Il banner è da scommentare in caso in cui si voglia inserire un messaggio fisso in testa alla pagina (es. caso di malfunzionamento delle API) --> testo da aggiornare*/}
            {/* <div className={isMobile ? 'issue-banner phone' : 'issue-banner pc'}>We are currently addressing issues with answer generation and expect to resolve them within the next 24 hours. We apologize for any inconvenience and appreciate your patience as we work to improve your experience. - Posted on November 9, 2023, at 13:00 CET</div> */}
            <div style={showInfo || showProfile || showHelpCenter ? { display: 'none' } : null}>
                        <MessageContainer />
                        <SendMessageContainer />
                    </div>
                    <HelpCenterPage />
                    <InfoPage />
                    <ProfilePage />
                </Col>
            </Row>
            } 
            <Outlet />
        </Container></CacheBuster>
    );
}

export default AppContainer;
