import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { setVisibilityInit } from "../../../slice/ModalVisibilitySlice";
import { selectBearer, selectController, selectDeleteAccount, selectExpired, selectRefreshToken, setBearer, setController, setDeleteAccount, setExpired, setRefreshToken } from '../../../slice/bearerSlice';
import { jwtDecode } from "jwt-decode";
import { resetUserData } from '../../../slice/userDataSlice';
import { setMessageProfileValInit } from '../../../slice/messageProfileValidationSlice';
import { setErrModalValInit } from '../../../slice/errModalValidationSlice';
import { setInputMessage } from '../../../slice/InputMessageSlice';
import { initMessage } from '../../../slice/MessageSlice';
import { setInitiSideMenu } from '../../../slice/SideMenuSlice';
import { Url } from '../../../constants/global';
import { resetLanguage } from "../../../slice/SettingSlice";
import { setClickTranslate, setStreaming, setTranslating } from "../../../slice/loaderSlice";
import { useEffect } from "react";
import { setRequest } from "../../../slice/userActionsSlice";
import PromptFirstLoginModal from "./PromptFirstLogin/PromptFirstLoginModal";

function LogOut() {
    const dispatch = useDispatch();
    const logToken = useSelector(selectBearer);
    const refreshToken = useSelector(selectRefreshToken);
    const expiredLogToken = useSelector(selectExpired);
    const controller = useSelector(selectController);
    const deleteAccount = useSelector(selectDeleteAccount);
    let date = new Date();
    const decodeLogToken = (() => {
        try {
            return jwtDecode(logToken)
        }
        catch (e) {
            return ''
        }
    })();

    useEffect(() => {
        if ((decodeLogToken !== '' && logToken !== '' && decodeLogToken.exp * 1000 < date.getTime()) || expiredLogToken) {
            requestNewBearer();
        }
    }, [expiredLogToken])

    useEffect(() => {
        if (deleteAccount) {
            dispatch(setDeleteAccount(false));
            logOut();
        }
    }, [deleteAccount])

    function expiredToken() {
        logOut();
        dispatch(setErrModalValInit());
        dispatch(setMessageProfileValInit());
        alert("Your session has expired.");
    }

    function logOut() {
        let date2 = new Date(); //serve perché l'altra date non sempre è aggiornata (caso in cui clicco su logout quando il token è già scaduto)
        if (decodeLogToken !== '' && decodeLogToken.exp * 1000 > date2.getTime()) { //when token is still valid
            invalidLogToken();
        }
        console.log('chiama il logout????')
        dispatch(setClickTranslate({ show: false }));
        dispatch(setTranslating(false));
        dispatch(setStreaming(false));
        dispatch(setBearer(''));
        dispatch(setRefreshToken(''));
        dispatch(resetUserData());
        dispatch(setInputMessage(''));
        dispatch(initMessage());
        dispatch(setExpired(false));
        dispatch(setInitiSideMenu());
        dispatch(setVisibilityInit());
        dispatch(resetLanguage());
        dispatch(setRequest(0));
        controller.abort();
        dispatch(setController(new AbortController()));
    }

    function invalidLogToken() { //da rivedere perché dà degli errori ed entra anche quando il token è scaduto
        const fetchPromise = fetch(Url + '/varchat/api/auth/logout', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` }
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                }
            })
            .catch((error) => {
                console.error(`Could not get products: ${error}`);
            });
    }

    function requestNewBearer() {
        const fetchPromise = fetch(Url + '/varchat/api/auth/refresh-token', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ refresh_token: refreshToken }) 
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                console.log('set new bearer')
                dispatch(setBearer(data.access_token));
                dispatch(setExpired(false));
            })
            .catch((error) => {
                expiredToken(); //se dà errore lo sloggo perché non ho più un token valido
                console.error(`Could not get products: ${error}`);
            });
    }

    return (
        <>
            <Button onClick={() => { logOut() }} className='side-menu-buttons'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 21v-2h7V5h-7V3h7c.55 0 1.02.196 1.413.587C20.803 3.98 21 4.45 21 5v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21h-7zm-2-4-1.375-1.45 2.55-2.55H3v-2h8.175l-2.55-2.55L10 7l5 5-5 5z" fill="#344D66" />
                </svg>
                LOG OUT
            </Button>
            <PromptFirstLoginModal />
        </>
    );
}

export default LogOut