import { createSlice } from '@reduxjs/toolkit';

const initialState= {theme: window.matchMedia("(prefers-color-scheme: light)").matches ? 'light' : 'dark', language: 'EN'};

export const SettingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setTheme: (state) => {
            if(state.theme === 'light'){
                state.theme = 'dark';
            } else {
                state.theme = 'light';
            }
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        resetLanguage: (state) => {
            state.language = 'EN';
        },
    }
});

export const { setTheme, setLanguage, resetLanguage } = SettingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectTheme = (state) => state.settings.theme;
export const selectLanguage = (state) => state.settings.language;

export default SettingSlice.reducer;