import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectResetPassword, setMailForgotSent, setResetPassword } from '../../../slice/ModalVisibilitySlice';
import { setPasswordInit, selectPassword } from '../../../slice/passwordSlice';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setErrModalVal, selectErrModalVal, setErrModalValInit } from '../../../slice/errModalValidationSlice';
import Password from './Password';
import TooltipPass from './TooltipPass';
import { Url } from '../../../constants/global';
import { selectTheme } from '../../../slice/SettingSlice.js';
import { isMobile } from 'react-device-detect';

function ResetPassword() {
    const show = useSelector(selectResetPassword);
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const password = useSelector(selectPassword);
    const errValidation = useSelector(selectErrModalVal);
    const navigate = useNavigate();
    let errPass;
    let errReset;
    const urlParams = new URLSearchParams(window.location.search);
    const theme = useSelector(selectTheme);
    const [linkExpired, setLinkExpired] = useState(false);

    dispatch(setResetPassword(true));

    const handleClose = () => {
        dispatch(setResetPassword(false));
        setValidated(false);
        setLinkExpired(false);
        dispatch(setPasswordInit());
        dispatch(setErrModalValInit());
        navigate("/");
    };

    function handleSubmit(event) {
        const form = event.currentTarget;
        setValidated(true);
        event.preventDefault();
        if (form.checkValidity() === false || password.pass !== password.confirm) {
            event.stopPropagation();
        } else {
            const fetchPromise = fetch(Url + '/varchat/api/auth/confirm-new-password', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: password.pass, user: urlParams.get('user'), code: urlParams.get('code') })
            });

            fetchPromise
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 400) {
                            setLinkExpired(true);
                        } else if (response.status === 401) {
                            dispatch(setErrModalVal({ show: true, value: 'Invalid password!' }));
                            throw new Error(`HTTP error: ${response.status}`);
                        } else {
                            dispatch(setErrModalVal({ show: true, value: 'An error occurred.' }));
                            throw new Error(`HTTP error: ${response.status}`);
                        }
                    } else {
                        handleClose();
                    }
                })
                .catch((error) => {
                    console.error(`Could not get products: ${error}`);
                });
        }
    }

    function sendMail() {
        const fetchPromise = fetch(Url + '/varchat/api/auth/forgot-password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: urlParams.get('user') })
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    dispatch(setErrModalVal({ show: true, value: 'An error occurred.' }));
                    throw new Error(`HTTP error: ${response.status}`);
                } else {
                    return response;
                }
            })
            .then(() => {
                handleClose();
                dispatch(setMailForgotSent(true));
            })
            .catch((error) => {
                console.error(`Could not get products: ${error}`);
            });
    }

    if (password.pass !== password.confirm) {
        errPass = "Passwords do not match.";
    } else {
        errPass = "";
    }

    if (errValidation.show) {
        errReset = <p className='mt-2 mb-0 errorMessage'>{errValidation.value}</p>
    } else {
        errReset = <></>
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false}>
                <Modal.Header>
                    <Modal.Title className='title'>
                        <svg className='modal-icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 14c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 5 12c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 7 10c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 7 14zm0 4c-1.667 0-3.083-.583-4.25-1.75C1.583 15.083 1 13.667 1 12c0-1.667.583-3.083 1.75-4.25C3.917 6.583 5.333 6 7 6c1.117 0 2.13.275 3.037.825A6.212 6.212 0 0 1 12.2 9H21l3 3-4.5 4.5-2-1.5-2 1.5-2.125-1.5H12.2a6.212 6.212 0 0 1-2.162 2.175C9.128 17.725 8.117 18 7 18zm0-2c.933 0 1.754-.283 2.463-.85A4.032 4.032 0 0 0 10.875 13H14l1.45 1.025L17.5 12.5l1.775 1.375L21.15 12l-1-1h-9.275a4.032 4.032 0 0 0-1.412-2.15C8.754 8.283 7.933 8 7 8c-1.1 0-2.042.392-2.825 1.175C3.392 9.958 3 10.9 3 12s.392 2.042 1.175 2.825C4.958 15.608 5.9 16 7 16z" fill="#5ABEBA" />
                        </svg>
                        Reset password</Modal.Title>
                    <Button onClick={handleClose} id='close' className='close-button'>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Container>
                            {linkExpired ? <><Row>
                                <div className='center mb-4' style={{ fontWeight: '500' }}>Your reset password link has expired.<br />Please request a new one using the button below.</div>
                            </Row>
                                <Row>
                                    <Button onClick={sendMail} className="primary-button " type='button'>REQUEST RESET PASSWORD LINK</Button>
                                </Row>
                            </> : <>
                                <Form.Group>
                                    <Row className='input-row'>
                                        <span className='label-with-info'>
                                            <label>PASSWORD</label>
                                            <TooltipPass />
                                        </span>
                                        <Password value='pass'></Password>
                                        <Form.Control.Feedback type="invalid" className='invalid-password'>Please enter a valid password.</Form.Control.Feedback>
                                    </Row>
                                    <Row className="input-row">
                                        <label id='confirm'>CONFIRM PASSWORD</label>
                                        <Password value='confirm'></Password>
                                        <span className='invalid-password'>{validated ? errPass : null}</span>
                                    </Row>
                                </Form.Group>
                                <Row><Button className="primary-button" type='submit' id='signin'>
                                    CONFIRM NEW PASSWORD
                                </Button></Row>
                                <Row>
                                    {errReset}
                                </Row>
                            </>}
                        </Container>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ResetPassword;