import { useSelector } from "react-redux";
import SendMessageBar from "./SendMessageBar";
import SendMessageButton from "./SendMessageButton";
import './sendMessageContainer.css';
import { selectDevice } from "../../slice/deviceSlice";

function SendMessageContainer() {
    const device = useSelector(selectDevice);

    return (
        <div name='sendMessageContainer' className={device}>
            <div name='sendMessageInput'>
                <SendMessageBar />
                <SendMessageButton />
            </div>
            <div name='footer-info'>VarChat may provide inaccurate answers; it is advisable to verify critical information independently</div>
        </div>
    );
}

export default SendMessageContainer;