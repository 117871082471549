import { Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { selectTerms, setTerms } from '../../../slice/ModalVisibilitySlice';
import { isMobile } from 'react-device-detect';
import { selectTheme } from '../../../slice/SettingSlice';

function TermsOfUse() {
    const dispatch = useDispatch();
    const show = useSelector(selectTerms);
    const theme = useSelector(selectTheme);

    const handleClose = (e) => {
        e.stopPropagation();
        dispatch(setTerms(false));
    };

    return (
        <>
            <Modal show={show} onHide={(e)=>handleClose(e)} backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false} dialogClassName={!isMobile ? 'modal-dim' : ''}>
                <Modal.Header>
                    <Modal.Title className='title'>
                        <svg className='modal-icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 18H16V16H8V18ZM8 14H16V12H8V14ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H14L20 8V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 9V4H6V20H18V9H13Z" fill="#5ABEBA" />
                        </svg>
                        Terms of Use</Modal.Title>
                    <Button onClick={handleClose} id='close' className='close-button'>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <div>
                                <ol>
                                    <li className='custom-li'><b>Acceptance of Terms</b>
                                        <p> By accessing and using the VarChat Platform ("Platform"), you agree to abide by these Terms of Use. If you do not agree with these terms, please do not use the Platform.
                                        </p>
                                    </li>
                                    <li className='custom-li'>
                                        <b>Description of Service</b>
                                        <p>The Platform provides users with tools to navigate variant combinations using different search modes. This service is intended for research and informational purposes only.
                                        </p>
                                    </li>
                                    <li className='custom-li'>
                                        <b>Access to the platform</b>
                                        <p>Open Access: A portion of the VarChat platform is open access, allowing all users to view and utilize specific content without any restrictions.<br />
                                            Registration-Based Access: Some contents and features of the VarChat platform are available exclusively to registered users. Registration is free of charge. Upon successful registration, users will gain access to these exclusive contents.<br />
                                            All registered user data will be handled in accordance with our Privacy Policy.
                                        </p>
                                    </li>
                                    <li className='custom-li'>
                                        <b>Research Use Only</b>
                                        <p>
                                            The VarChat platform and its contents are intended for research purposes only and should not be used for clinical or diagnostic procedures. Users should exercise caution and rely on their professional judgment when interpreting and applying the data and information obtained from the platform.<br />
                                            enGenome and its affiliates do not assume responsibility for any clinical or therapeutic decisions made based on the content of the VarChat platform.
                                        </p>
                                    </li>
                                    <li className='custom-li'>
                                        <b>User Conduct</b>
                                        <div className='mb-3'>
                                            Users are prohibited from:
                                            <ul>
                                                <li>Using the Platform for any illegal or unauthorized purpose.</li>
                                                <li>Attempting to hack, disrupt, or misuse the Platform.</li>
                                                <li>Reproducing, duplicating, or reselling any part of the Platform without express permission.</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className='custom-li'>
                                        <b>Intellectual Property</b>
                                        <p>All content, features, and functionality on the Platform are the exclusive property of enGenome and are protected by international copyright laws.
                                        </p>
                                    </li>
                                    <li className='custom-li'>
                                        <b>Limitation of Liability</b><p>
                                            enGenome shall not be liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use the Platform.
                                        </p>
                                    </li>
                                    <li className='custom-li'>
                                        <b>Changes to Terms of Use</b>
                                        <p>enGenome reserves the right to modify these Terms of Use at any time. Users are encouraged to review the terms regularly.
                                        </p>
                                    </li>
                                    <li className='custom-li'>
                                        <b>Termination</b><p>
                                            enGenome reserves the right to terminate access to the Platform for users who violate these Terms of Use.</p>
                                    </li>
                                    <li className='custom-li'>
                                        <b>Data Privacy</b><p>
                                            Any data or information provided by users will be treated in accordance with enGenome's Privacy Policy.</p>

                                    </li>
                                    <li className='custom-li'>
                                        <b>Governing Law</b>
                                        <p>These Terms of Use shall be governed by the laws of the jurisdiction in which enGenome operates (Italy).
                                        </p>
                                    </li>
                                    <li className='custom-li'>
                                        <b>Contact</b>
                                        <p>For any questions or concerns regarding these Terms of Use, please contact enGenome's support team at <a className='link-generic' href='mailto:info@engenome.com'>info@engenome.com</a>.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default TermsOfUse;