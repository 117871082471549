import robotDark from '../Images/varchat-icon-robot-dark.svg';
import varchatLogo from '../Images/VarChat Logo DB_SVG.svg';
import './loadingPage.css';
import { Row } from 'react-bootstrap';

function LoadingPage(props){

    return(
        <Row className={'loadingPage ' + (props.showLoader ? '' : 'collapse')}>
            <img src={varchatLogo} alt='VarChat logo' style={{width: '160px'}}/>
            <img src={robotDark} alt='varChat' className='loading-robot-icon'/>
            <div>A product by <span style={{color:'var(--green)'}}>enGenome</span></div>
        </Row>
    )
}

export default LoadingPage;