import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage } from '../../slice/SettingSlice';
import { selectMessages, setMessageTranslation, updateMessage, updateMessageTranslation } from '../../slice/MessageSlice';
import flagLegend from '../../constants/flagLegend';
import { Url } from '../../constants/global';
import { selectBearer, setExpired } from '../../slice/bearerSlice';
import { selectStreaming, setClickTranslate, setTranslating, selectTranslating } from '../../slice/loaderSlice';
import { translateGA } from '../../GoogleAnalytics4/GoogleAnalyticsEvents';
import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
// import { fetchEventSource } from '@microsoft/fetch-event-source';

function MessageFlagIcons(props) {
    const dispatch = useDispatch();
    const language = useSelector(selectLanguage);
    const message = useSelector(selectMessages)[props.ind];
    const logToken = useSelector(selectBearer);
    const streaming = useSelector(selectStreaming);
    const translating = useSelector(selectTranslating);
    const wait = (streaming || translating ? true : false);
    const [triggerAfterUpBearer, setTriggerAfterUpBearer] = useState(false);

    useEffect(()=>{
        if(triggerAfterUpBearer){ //To trigger the function that had given 401 because the token had expired
            setTriggerAfterUpBearer(false);
            if(logToken !== ''){
                getTranslation();
            } else {
                dispatch(setTranslating(false));
            }
        }
    }, [logToken])

    async function translate(e) {
        if (!wait) {
            dispatch(setClickTranslate({show:true, i: props.ind})); //serve settarlo a true per evitare che aggiornando i messaggi si scrolli nella pagina
            dispatch(updateMessage({ i: props.ind, obj: {language: language} }));
            if (message.translation === undefined || message.translation[language] === undefined || message.translation[language] === 'error') {                
                dispatch(setTranslating(true));
                translateGA();
                getTranslation();
                // try{ //per streammare la response, da decommentare anche la func
                //     await getTranslationStream(); 
                // } catch (error){
                //     console.error(`Could not get products: ${error}`);
                // }
            }
        }
    }

    function restoreOriginal(e) {
        if (!wait) {
            dispatch(setClickTranslate({show:true, i: props.ind}));
            dispatch(updateMessage({ i: props.ind, obj: {language: 'EN'} }));
        }
    }

    //funzione per prendere la traduzione con DeepL
    function getTranslation() {
        const transObj = {};
        const fetchPromise = fetch(Url + '/varchat/api/translate/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` },
            body: JSON.stringify({ answer: message.text, lang: language })
        });

        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        dispatch(setClickTranslate({show: false}));
                        dispatch(setExpired(true));
                        setTriggerAfterUpBearer(true);
                        console.error(`HTTP error: ${response.status}`);
                    } else if (response.status === 409) {
                        transObj[language] = 'error';
                        const updateTrasObj = Object.assign({},message.translation, transObj);
                        dispatch(setMessageTranslation({ translation: updateTrasObj, i: props.ind }));
                        dispatch(setClickTranslate({show:true, i: props.ind}));
                        dispatch(setTranslating(false));
                        throw new Error(`HTTP error: ${response.status}`);
                    } else {
                        dispatch(setTranslating(false));
                        throw new Error(`HTTP error: ${response.status}`);
                    }
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                if(data !== undefined){
                    transObj[language] = data.translated_answer;
                    const updateTrasObj = Object.assign({},message.translation, transObj);
                    dispatch(setMessageTranslation({ translation: updateTrasObj, i: props.ind }));
                    dispatch(setClickTranslate({show:true, i: props.ind}));
                    dispatch(setTranslating(false));
                }
            })
            .catch((error) => {
                transObj[language] = 'error';
                const updateTrasObj = Object.assign({},message.translation, transObj);
                dispatch(setMessageTranslation({ translation: updateTrasObj, i: props.ind }));
                dispatch(setClickTranslate({show:true, i: props.ind}));
                dispatch(setTranslating(false));
                console.error(`Could not get products: ${error}`);
            });
    }

    //funzione per streammare la traduzione (caso di utilizzo di GPT)
    //to be finished and tested

    // async function getTranslationStream() {
    //     let transObj = {};
    //     await fetchEventSource(Url + '/varchat/api/stream/translate/', {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' }, //"Authorization": `Bearer ${logToken}` manca da be, aspetta a inserirlo
    //         body: JSON.stringify({ answer: message.text, lang: language }),
    //         async onopen(response) {
    //             if (!response.ok) {
    //                 if (response.status === 401) {
    //                     dispatch(setClickTranslate({show: false}));
    //                     dispatch(setExpired(true));
                        // setTriggerAfterUpBearer(true);
    //                     console.error(`HTTP error: ${response.status}`);
    //                 } else if (response.status === 409) {
    //                     transObj[language] = 'error';
    //                     const updateTrasObj = Object.assign({},message.translation, transObj);
    //                     dispatch(setMessageTranslation({ translation: updateTrasObj, i: props.ind }));
    //                     dispatch(setClickTranslate({show:true, i: props.ind}));
    //                     throw new Error(`HTTP error: ${response.status}`);
    //                 } else {
    //                     throw new Error(`HTTP error: ${response.status}`);
    //                 }
    //                 dispatch(setTranslating(false));
    //             } else {
    //                 transObj[language] = "";
    //                 const updateTrasObj = Object.assign({},message.translation, transObj);
    //                 dispatch(updateMessage({ i: props.ind, obj: {translation: updateTrasObj}}));
    //                 dispatch(setClickTranslate({show:true, i: props.ind}));
    //                 return;
    //             }
    //         },
    //         onmessage(msg) { //da vedere che errori gestire
    //             console.log('data', msg.data)
    //             if(msg.data !== undefined){
    //                 dispatch(updateMessageTranslation({ translation: msg.data, i: props.ind }));
    //             }
    //         },
    //         onclose() {
    //             dispatch(setTranslating(false));
    //             console.log('close');
    //         },
    //         onerror(error) {
    //             transObj[language] = 'error';
    //             const updateTrasObj = Object.assign({},message.translation, transObj);
    //             dispatch(setMessageTranslation({ translation: updateTrasObj, i: props.ind }));
    //             dispatch(setClickTranslate({show:true, i: props.ind}));
    //             dispatch(setTranslating(false));
    //             throw error;
    //         }
    //     });
    // }

    return (
        <span>
            {message.language !== 'EN' ?
                <button className='secondary-button small display-align-center' name='translation-btn' onClick={(e) => { restoreOriginal(e) }} disabled={wait}>{isMobile ? '' : 'TRANSLATE TO'}
                    <span className={"fi fi-gb " + (isMobile ? '' : 'ms-1 ') + (wait ? ' img-disabled' : '')} title="EN" name='flag-icon'/>               
                </button> :
                <button className='secondary-button small display-align-center' name='translation-btn' onClick={(e) => { translate(e) }} disabled={wait}>{isMobile ? '' : 'TRANSLATE TO'}
                    <span className={"fi " + (isMobile ? '' : 'ms-1 ') + (wait ? ' img-disabled' : '') + ' fi-' + flagLegend[language]} title={language} name='flag-icon'/>               
                </button>
            }
        </span>
    );
}

export default MessageFlagIcons;