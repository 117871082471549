import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setTerms } from '../../../slice/ModalVisibilitySlice';
import TermsOfUse from './TermsOfUse';
import { setShowSide } from '../../../slice/SideMenuSlice';

function TermsButton(){
    const dispatch = useDispatch();
    
    function openTerms(e){
        e.stopPropagation();
        dispatch(setShowSide(false)); //to close side menu in phone
        dispatch(setTerms(true));
    }

    return(
        <>
            <Button onClick={(e)=> {openTerms(e)}} className='side-menu-footer-buttons'>
                <span>Terms of Use</span>
            </Button>
            <TermsOfUse/>
        </>
    );
}

export default TermsButton;