import { useRouteError } from "react-router-dom";
import './error-page.css'

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <svg width="102" height="102" viewBox="0 0 102 102" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
        <path d="M1 51c0 27.614 22.386 50 50 50s50-22.386 50-50S78.614 1 51 1 1 23.386 1 51" fill="#FFF"/>
        <path d="M100.008 51c0 27.066-21.94 49.009-49.009 49.009-27.066 0-49.008-21.943-49.008-49.009S23.933 1.991 51 1.991c27.068 0 49.01 21.943 49.01 49.009z" stroke="#314C67" strokeWidth="2"/>
        <path d="M51 98.018C25.075 98.018 3.982 76.926 3.982 51 3.982 25.075 25.075 3.983 51 3.983c25.927 0 47.019 21.092 47.019 47.017 0 25.926-21.092 47.018-47.02 47.018z" stroke="#4FC7BE" strokeWidth="2"/>
        <path d="M37.618 38.256a4.593 4.593 0 0 1-4.587-4.587 4.593 4.593 0 0 1 4.587-4.587 4.593 4.593 0 0 1 4.587 4.587 4.593 4.593 0 0 1-4.587 4.587z" stroke="#4FC7BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M40.205 33.669a2.587 2.587 0 1 1-5.174 0 2.587 2.587 0 0 1 5.174 0" fill="#FFF"/>
        <path d="M40.205 33.669a2.587 2.587 0 1 1-5.174 0 2.587 2.587 0 0 1 5.174 0z" stroke="#314C67" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M64.382 38.256a4.593 4.593 0 0 1-4.587-4.587 4.593 4.593 0 0 1 4.587-4.587 4.593 4.593 0 0 1 4.587 4.587 4.593 4.593 0 0 1-4.587 4.587z" stroke="#4FC7BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M66.97 33.669a2.587 2.587 0 1 1-5.174 0 2.587 2.587 0 0 1 5.173 0" fill="#FFF"/>
        <path d="M66.97 33.669a2.587 2.587 0 1 1-5.174 0 2.587 2.587 0 0 1 5.173 0z" stroke="#314C67" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M71.833 72.918a2 2 0 0 1-2-2c0-10.385-8.448-18.833-18.833-18.833-10.384 0-18.832 8.448-18.832 18.833a2 2 0 0 1-4 0c0-12.59 10.242-22.833 22.832-22.833s22.833 10.243 22.833 22.833a2 2 0 0 1-2 2z" stroke="#4FC7BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30.168 70.918c0-11.505 9.327-20.833 20.831-20.833 11.506 0 20.833 9.328 20.833 20.833" stroke="#314C67" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
</svg>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}