import { configureStore } from '@reduxjs/toolkit';
import messagesReducer from '../slice/MessageSlice';
import inputMessageReducer from '../slice/InputMessageSlice';
import settingReducer from '../slice/SettingSlice';
import visibilityReducer from '../slice/ModalVisibilitySlice';
import loaderReducer from '../slice/loaderSlice';
import deviceReducer from '../slice/deviceSlice';
import sideMenureducer from '../slice/SideMenuSlice';
import passwordReducer from '../slice/passwordSlice';
import dataReducer from '../slice/userDataSlice';
import bearerReducer from '../slice/bearerSlice';
import errorModalValReducer from '../slice/errModalValidationSlice';
import messageProfileValReducer from '../slice/messageProfileValidationSlice';
import userActionsReducer from '../slice/userActionsSlice';

import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import { createStateSyncMiddleware, initMessageListener} from "redux-state-sync";

import createFilter from 'redux-persist-transform-filter';

const persistConfig = {
  key: 'root',
  storage: storageSession,
}

const persistConfigToken = {
  key: 'bearer',
  storage,
  whitelist: ['value', 'refreshToken']
}

const persistConfigUser = {
  key: 'user',
  storage,
  whitelist: ['value']
}

// const filter = createFilter(
//   `value.language`
// );

const persistConfigLanguage = {
  key: 'settings',
  storage,
  whitelist: ['language']
}

const persistedReducer = combineReducers({
    messages: messagesReducer,
    sidemenu: sideMenureducer,
    inputmessage: inputMessageReducer,
    settings: persistReducer(persistConfigLanguage, settingReducer),
    visibility: visibilityReducer,
    loader: loaderReducer,
    device: deviceReducer,
    password: passwordReducer,
    data: persistReducer(persistConfigUser, dataReducer),
    bearer: persistReducer(persistConfigToken, bearerReducer),
    errorModalVal: errorModalValReducer,
    messageProfileVal: messageProfileValReducer,
    useractions: userActionsReducer
});

const allPersistedReducer = persistReducer(persistConfig, persistedReducer);

export const store = configureStore({
  reducer: allPersistedReducer,
  middleware: [createStateSyncMiddleware({whitelist:['bearer/setBearer', 'bearer/setRefreshToken', 'data/setUserData', 'data/resetUserData', 'settings/setLanguage', 'settings/resetLanguage']})],
});

initMessageListener(store);

export const persistor = persistStore(store)