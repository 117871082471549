import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import './SubscriptionNewsletter.css';
import { selectDevice } from '../../../slice/deviceSlice';
import { selectNewsLetterShow, setNewsletterShow } from '../../../slice/ModalVisibilitySlice';
import { setShowSide } from '../../../slice/SideMenuSlice';

function SubscriptionButton() {
    const dispatch = useDispatch();
    const device = useSelector(selectDevice);
    const newsLetterShow = useSelector(selectNewsLetterShow);

    function onClickNewsletterButton() {
        dispatch(setShowSide(false)); //to close side menu in phone
        dispatch(setNewsletterShow(!newsLetterShow));
    }

    return (
        <>
            <Button onClick={() => { onClickNewsletterButton() }} className={'side-menu-buttons '+ (newsLetterShow ? 'active ' : '') + device}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 20c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4h16c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 20H4zm8-7L4 8v10h16V8l-8 5zm0-2 8-5H4l8 5zM4 8V6v12V8z" fill="#5ABEBA" />
                </svg>
                SUBSCRIBE TO NEWSLETTER
            </Button>
        </>
    );
}

export default SubscriptionButton;