import './loader.css';

function Loader(props) {
    const loading_message = (props.loading_message ? props.loading_message : 'PLEASE WAIT');

    return (
        <div className={"bubble"+ (props.loading_message ? ' translating-loader' : '')}>
            <span className='text-label-style'>{loading_message}</span>
            <div className="bubbleDot"></div>
            <div className="bubbleDot"></div>
            <div className="bubbleDot"></div>
        </div>
    );
}

export default Loader;