import { Button } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { setShowLogin } from '../../../slice/ModalVisibilitySlice';
import RegistrationSuccess from './RegistrationSuccess';
import MailForgotSent from './MailForgotSent';
import AccountIcon from '../../../Images/AccountIcon';
import { setShowSide } from '../../../slice/SideMenuSlice';

function LoginButton(){
    const dispatch = useDispatch();

    function opeLogin(){
        dispatch(setShowLogin({show: true, type: 'logButton'}))
        dispatch(setShowSide(false)); //to close side menu in phone
    }

    return(
        <>
            <Button onClick={()=> {opeLogin()}} className='secondary-button blue-bg mt-4' style={{width: '100%'}}>
                <AccountIcon />
                LOG IN
            </Button>
            <RegistrationSuccess/>
            <MailForgotSent/>
        </>
    );
}

export default LoginButton