import TooltipPass from '../RegistrationLogin/TooltipPass';
import Password from '../RegistrationLogin/Password';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { selectPassword, setPasswordInit } from '../../../slice/passwordSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectMessageProfileVal, setMessageProfileVal } from '../../../slice/messageProfileValidationSlice';
import { selectBearer, setExpired } from '../../../slice/bearerSlice';
import { useState, useEffect } from 'react';
import { Url } from '../../../constants/global';
import { isMobile } from 'react-device-detect';

function ChangePassword(props) {
    let errPass, messagePassword;
    const dispatch = useDispatch();
    const messageValidation = useSelector(selectMessageProfileVal);
    const password = useSelector(selectPassword);
    const [open, setOpen] = useState(false);
    const logToken = useSelector(selectBearer);
    const [triggerAfterUpBearer, setTriggerAfterUpBearer] = useState(false);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (triggerAfterUpBearer) { //To trigger the function that had given 401 because the token had expired
            setTriggerAfterUpBearer(false);
            if (logToken !== '') {
                putPassword();
            } else {
                props.handleClose();
            }
        }
    }, [logToken])

    function reset() {
        closeProfileMessage();
        props.val[1]({ ...props.val[0], password: false });
        dispatch(setPasswordInit());
    }

    function closeProfileMessage(){
        dispatch(setMessageProfileVal(messageValidation.map((err) => {
            if (err.id === 'personalInfo') {
                return { ...err, show: false };
            } else {
                return { ...err };
            }})));
    }

    function handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        if (!(password.old === '' && password.pass === '' && password.confirm === '')) {
            props.val[1]({ ...props.val[0], password: true });
            if (form.checkValidity() === false || errPass !== "") {
                event.stopPropagation();
            } else {
                closeProfileMessage();
                putPassword();
                //invio i dati e attendo che mi confermino che la vecchia password corrisponde. (non aggiorno nessuno stato perché non ho la pass salvata)
            }
        }
    }

    //function to send data
    function putPassword() {
        setDisable(true);
        const fetchPromise = fetch(Url + '/varchat/api/auth/change-password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${logToken}` },
            body: JSON.stringify({
                proposedPassword: password.pass,
                previousPassword: password.old
            })
        });
        fetchPromise
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 409) {
                        dispatch(setMessageProfileVal(messageValidation.map((err) => {
                            if (err.id === 'password') {
                                return { ...err, show: true, value: 'Incorrect old password!', type: 'error' };
                            } else {
                                return { ...err };
                            }
                        })));
                        setDisable(false);
                        throw new Error(`HTTP error: ${response.status}`);
                    } else if (response.status === 401) {
                        dispatch(setExpired(true));
                        setTriggerAfterUpBearer(true);
                    } else {
                        setDisable(false);
                        throw new Error(`HTTP error: ${response.status}`);
                    }
                } else {
                    dispatch(setMessageProfileVal(messageValidation.map((err) => {
                        if (err.id === 'password') {
                            return { ...err, show: true, value: 'Password successfully changed!', type: 'success' };
                        } else {
                            return { ...err };
                        }
                    })));
                    props.val[1]({ ...props.val[0], password: false });
                    dispatch(setPasswordInit());
                    setDisable(false);
                }
            })
            .catch((error) => {
                setDisable(false);
                console.error(`Could not get products: ${error}`);
            });
    }

    if (password.old === password.pass && password.old !== '') {
        errPass = "New password must differs from the old!";
    } else if (password.pass !== password.confirm) {
        errPass = "Passwords do not match!";
    } else {
        errPass = "";
    }

    messageValidation.map((message) => {
        if (message.id === 'password' && message.show === true) {
            if (message.type === 'error') {
                messagePassword = <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17c.283 0 .52-.096.713-.288A.968.968 0 0 0 13 16a.968.968 0 0 0-.287-.713A.968.968 0 0 0 12 15a.968.968 0 0 0-.713.287A.968.968 0 0 0 11 16c0 .283.096.52.287.712.192.192.43.288.713.288zm-1-4h2V7h-2v6zm-2.75 8L3 15.75v-7.5L8.25 3h7.5L21 8.25v7.5L15.75 21h-7.5zm.85-2h5.8l4.1-4.1V9.1L14.9 5H9.1L5 9.1v5.8L9.1 19z" fill="#ee8373" />
                    </svg>
                    <span className='errorMessage'>{message.value}</span></>;
            } else {
                messagePassword = <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.95 12.7 9.5 11.275A.933.933 0 0 0 8.812 11c-.274 0-.512.1-.712.3a.948.948 0 0 0-.275.7c0 .284.092.517.275.7l2.15 2.15c.2.2.433.3.7.3.267 0 .5-.1.7-.3l4.25-4.25c.2-.2.296-.433.287-.7a1.055 1.055 0 0 0-.287-.7 1.02 1.02 0 0 0-.713-.312.93.93 0 0 0-.712.287L10.95 12.7zm-2.8 9.05L6.7 19.3l-2.75-.6a.943.943 0 0 1-.6-.387.928.928 0 0 1-.175-.688L3.45 14.8l-1.875-2.15a.934.934 0 0 1-.25-.65c0-.25.083-.466.25-.65L3.45 9.2l-.275-2.825a.928.928 0 0 1 .175-.687.943.943 0 0 1 .6-.388l2.75-.6 1.45-2.45a.983.983 0 0 1 .55-.437.97.97 0 0 1 .7.037l2.6 1.1 2.6-1.1a.97.97 0 0 1 .7-.037c.233.075.417.22.55.437L17.3 4.7l2.75.6c.25.05.45.18.6.388.15.208.208.437.175.687L20.55 9.2l1.875 2.15c.167.184.25.4.25.65s-.083.467-.25.65L20.55 14.8l.275 2.825a.928.928 0 0 1-.175.688.943.943 0 0 1-.6.387l-2.75.6-1.45 2.45a.983.983 0 0 1-.55.438.97.97 0 0 1-.7-.038l-2.6-1.1-2.6 1.1a.97.97 0 0 1-.7.038.983.983 0 0 1-.55-.438zm1.3-1.8 2.55-1.1 2.6 1.1 1.4-2.4 2.75-.65-.25-2.8 1.85-2.1-1.85-2.15.25-2.8-2.75-.6-1.45-2.4L12 5.15l-2.6-1.1L8 6.45l-2.75.6.25 2.8L3.65 12l1.85 2.1-.25 2.85 2.75.6 1.45 2.4z" fill="#5ABEBA" />
                    </svg>
                    <span className='successMessage'>{message.value}</span></>;
            }
        }
        return null;
    })

    const oldPass = <Col><label>OLD PASSWORD</label>
        <Password />
        <Form.Control.Feedback type="invalid" className='invalid'>Please enter your old password.</Form.Control.Feedback>
    </Col>;

    const newPass = <Col><span><label>NEW PASSWORD</label> <TooltipPass /></span>
        <Password value='pass' />
        <Form.Control.Feedback type="invalid" className='invalid'>Please enter a new valid password.</Form.Control.Feedback>
    </Col>;

    const confirmPass = <Col><label>CONFIRM PASSWORD</label>
        <Password value='confirm' />
        <div className='invalid-password'>{props.val[0].password ? errPass : null}</div>
    </Col>;

    const saveReset = <Col className={'display-align-center ' + (!isMobile ? 'mt-4' : null)}>
        <Button className="secondary-button" type='reset' onClick={() => reset()} disabled={disable} >RESET</Button>
        <Button className="ms-4 primary-button" type='submit' disabled={disable}>SAVE</Button>
    </Col>;

    return (
        <Form noValidate validated={props.val[0].password} onSubmit={(e) => { handleSubmit(e, 'password') }}>
            <Row className='mt-4'>
                <div className='profile-label password-label' onClick={() => { setOpen({ ...open, password: !open.password }) }}>Change password
                    <svg className={open.password ? 'rotate-arrow' : 'no-rotate-arrow'} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#344d66"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" /></svg>
                </div>
                <span className={(open.password ? ' profile-exp' : ' profile-compact')}>
                    {isMobile ?
                        <><Row className="input-row">{oldPass}</Row>
                            <Row className="input-row">{newPass}</Row>
                            <Row className="input-row">{confirmPass}</Row>
                            <Row className="input-row">{saveReset}</Row></>
                        :
                        <><Row className="input-row">{newPass}{oldPass}</Row>
                            <Row className="input-row">{confirmPass}{saveReset}</Row>
                            </>
                    }
                    {messageValidation[1].show ? <div className='profile-message mt-4 mb-4'> {messagePassword}</div> : null}
                </span>
            </Row></Form>
    );
}

export default ChangePassword;