import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import FormNewsletter from "./FormNewsletter";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "../../../slice/SettingSlice";
import { selectNewsLetterShow, setNewsletterShow } from "../../../slice/ModalVisibilitySlice";
import { isMobile } from 'react-device-detect';

function NewsletterModal() {
    const theme = useSelector(selectTheme);
    const dispatch = useDispatch();
    const show = useSelector(selectNewsLetterShow);

    function handleClose() {
        dispatch(setNewsletterShow(false));
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" className={'modal ' + theme} centered={isMobile ? 'centered' : false}>
                <Modal.Header>
                    <Modal.Title className='title'>
                        <svg className="modal-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 20c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4h16c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 20H4zm8-7L4 8v10h16V8l-8 5zm0-2 8-5H4l8 5zM4 8V6v12V8z" fill="#5ABEBA" />
                        </svg>
                        Subscribe to our newsletter</Modal.Title>
                    <Button onClick={handleClose} id='close' className='close-button'>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='close-icon'><g fill="none" fillRule="evenodd"><path fill="#344D66" fillRule="nonzero" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></g></svg>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                        <FormNewsletter />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NewsletterModal;