import { createSlice } from '@reduxjs/toolkit';

//addComment modal è gestita con useState perché ne apro una per ogni messaggio
//lo state qui serve per non triggerare lo scroll della pagina quando aggiorno message
const initialState = { addComment: false, termsOfUse: false, privacyPolicy: false, info: false, cookies:false, newsLetterShow: false, login: {show: false, type: 'logButton'}, signup: false,
    profile: {show:false, type: 'profileButton'}, registrationSuccess: false, mailForgotSent: false, resetPassword: false, activate:false, promptFirstLogin: false, deleteAccount: false, helpCenter: false};

// visibility of the modal
export const ModalVisibilitySlice = createSlice({
    name: 'visibility',
    initialState,
    reducers: {
        setShowAddComment: (state, action) => {
            state.addComment = action.payload;
        },
        setTerms: (state, action) => {
            state.termsOfUse = action.payload;
        },
        setPrivacy: (state, action) => {
            state.privacyPolicy = action.payload;
        },
        setInfo: (state, action) => {
            state.info = action.payload;
        },
        setCookiesModal: (state, action) => {
            state.cookies = action.payload;
        },
        setNewsletterShow: (state,action) => {
            state.newsLetterShow = action.payload;
        },
        setShowLogin: (state, action) => {
            state.login = action.payload;
        },
        setShowSignUp: (state, action) => {
            state.signup = action.payload;
        },
        setShowProfile: (state, action) => {
            state.profile = action.payload;
        },
        setShowRegistrationSuccess: (state, action) => {
            state.registrationSuccess = action.payload;
        },
        setMailForgotSent: (state, action) => {
            state.mailForgotSent = action.payload;
        },
        setResetPassword: (state, action) => {
            state.resetPassword = action.payload;
        },
        setActivateAccount: (state, action) => {
            state.activate = action.payload;
        },
        setPromptFirstLogin: (state, action) => {
            state.promptFirstLogin = action.payload;
        },
        setShowDeleteAccount: (state, action) => {
            state.deleteAccount = action.payload;
        },
        setShowHelpCenter: (state, action) => {
            state.helpCenter = action.payload;
        },
        setVisibilityInit: () => {
            return initialState;
        }
    }
});

export const { setShowAddComment, setTerms, setPrivacy, setInfo, setCookiesModal,setNewsletterShow, setShowLogin, setShowSignUp, setShowExample, setShowProfile, setShowRegistrationSuccess,
    setMailForgotSent, setResetPassword, setActivateAccount, setVisibilityInit, setPromptFirstLogin, setShowDeleteAccount, setShowHelpCenter} = ModalVisibilitySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectAddComment = (state) => state.visibility.addComment;
export const selectTerms = (state) => state.visibility.termsOfUse;
export const selectPrivacy = (state) => state.visibility.privacyPolicy;
export const selectInfo = (state) => state.visibility.info;
export const selectCookiesModal = (state) => state.visibility.cookies;
export const selectNewsLetterShow = (state) => state.visibility.newsLetterShow;
export const selectLogin = (state) => state.visibility.login;
export const selectSignUp = (state) => state.visibility.signup;
export const selectResetPassword = (state) => state.visibility.resetPassword;
export const selectActivateAccount = (state) => state.visibility.activate;
export const selectProfile = (state) => state.visibility.profile;
export const selectMailForgotSent = (state) => state.visibility.mailForgotSent;
export const selectRegistrationSuccess = (state) => state.visibility.registrationSuccess;
export const selectPromptFirstLogin = (state) => state.visibility.promptFirstLogin;
export const selectShowDeleteAccount = (state) => state.visibility.deleteAccount;
export const selectShowHelpCenter = (state) => state.visibility.helpCenter;

export default ModalVisibilitySlice.reducer;