import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { selectProfile, setInfo, setShowHelpCenter, setShowProfile } from '../../../slice/ModalVisibilitySlice';
import AccountIcon from '../../../Images/AccountIcon';
import { setShowSide } from '../../../slice/SideMenuSlice';

function ProfileButton() {
    const dispatch = useDispatch();
    const showProfile = useSelector(selectProfile).show;

    function openProfile(e){
        e.stopPropagation();
        dispatch(setShowSide(false)); //to close side menu in phone
        dispatch(setShowProfile({show:!showProfile, type: 'profileButton'}));
        dispatch(setInfo(false));
        dispatch(setShowHelpCenter(false));
    }

    return (
        <>
            <Button onClick={(e) => { openProfile(e) }} className={'side-menu-buttons '+ (showProfile ? ' active' : '')}>
                <AccountIcon />
                MY PROFILE
            </Button>
        </>
    );
}

export default ProfileButton