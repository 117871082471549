import { createSlice } from '@reduxjs/toolkit';

//controller is used to cancel requests while logging out
//deleteAccount is used to call the log out function after the account is deleted
const initialState={value:'', refreshToken: '', expired: false, controller: '', deleteAccount: false}; 

export const bearerSlice = createSlice({
    name: 'bearer',
    initialState,
    reducers: {
        setBearer: (state, action) => {
            state.value= action.payload;
        },
        setRefreshToken: (state, action) => {
            state.refreshToken= action.payload;
        },
        setExpired:  (state, action) => {
            state.expired= action.payload;
        },
        setController:  (state, action) => {
            state.controller= action.payload;
        },
        setDeleteAccount: (state, action) => {
            state.deleteAccount= action.payload;
        },
    }
});

export const { setBearer, setRefreshToken, setExpired, setController, setDeleteAccount } = bearerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state.
export const selectBearer = (state) => state.bearer.value;
export const selectRefreshToken = (state) => state.bearer.refreshToken;
export const selectExpired = (state) => state.bearer.expired;
export const selectController = (state) => state.bearer.controller;
export const selectDeleteAccount = (state) => state.bearer.deleteAccount;

export default bearerSlice.reducer;