const flagLegend = {
    AR: 'sa',
    BG: 'bg',
    ZH: 'cn',
    CS: 'cz',
    DA: 'dk',
    NL: 'nl',
    EN: 'gb',
    ET: 'ee',
    FI: 'fi',
    FR: 'fr',
    DE: 'de',
    EL: 'gr',
    HU: 'hu',
    ID: 'id',
    IT: 'it',
    JA: 'jp',
    KO: 'kr',
    LV: 'lv',
    LT: 'lt',
    NB: 'no',
    PL: 'pl',
    'PT-PT': 'pt',
    RO: 'ro',
    RU: 'ru',
    SK: 'sk',
    SL: 'si',
    ES: 'es',
    SV: 'se',
    TR: 'tr',
    UK: 'ua'
}

export default flagLegend;