import robot from '../../Images/varchat-icon-robot-2.svg';
import robotDark from '../../Images/varchat-icon-robot-dark.svg';
import { forwardRef, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectMessages } from '../../slice/MessageSlice';
import {isMobile} from 'react-device-detect';
import { selectTheme } from '../../slice/SettingSlice';

const LoadingMessage = forwardRef(function LoadingMessage(props, ref) {
    const timeRef = useRef(true);
    const [time, setTime] = useState('');
    const message = useSelector(selectMessages)[props.ind];
    const theme = useSelector(selectTheme);
    const robotIcon = theme === 'light' ? robot : robotDark;
    const [waitingMessage, setWaitingMessage] = useState('It may take up to 30 seconds.');
    setTimeout(()=>{
        setWaitingMessage('Your query might take longer; please wait another moment.');
    }, 30000)

    useEffect(()=>{ //to set time only once
        if(timeRef.current){
            setTime(getFormattedTime());
            timeRef.current= false;
        }
    },[])

    function getFormattedTime() {
        const date = new Date();
        const timeComponents = [date.getHours(), date.getMinutes()];
        return timeComponents
            .map(component => {
                const pad = (component < 10) ? '0' : '';
                return pad + component;
            })
            .join(':');
    }

    return (
        <><span className='message' ref={ref} key={'loading '+ props.ind}>
            {isMobile ? null : <img src={robotIcon} alt='VarChat' className='icon'></img>}
            <span name='message-text' className='varchat'>
                <span name='message-header'>
                    <span>
                        <p name='name'>VarChat</p>
                        <p name='time'>{time}</p>
                    </span>
                </span>
                <div> 
                    <span>{message.text}. {waitingMessage}</span>
                </div>
            </span>
        </span>
        </>
    );
})


export default LoadingMessage;