import './rating.css';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMessages, updateMessage } from '../../../slice/MessageSlice';
import AddCommentModal from './AddCommentModal';
import { clickStarsGA } from '../../../GoogleAnalytics4/GoogleAnalyticsEvents';
import { selectStreaming, selectTranslating } from '../../../slice/loaderSlice';
import { setShowAddComment } from '../../../slice/ModalVisibilitySlice';

function Rating(props) {
    const stars = [];
    const dispatch = useDispatch();
    const [showAddComment, setShowAddComment2] = useState(false);
    const message = useSelector(selectMessages)[props.ind];
    const streaming = useSelector(selectStreaming);
    const translating = useSelector(selectTranslating);
    const wait = (streaming || translating ? true : false);

    function clickStar(i) {
        if (!wait) {
            dispatch(updateMessage({ i: props.ind, obj: { rate: i } }))
            clickStarsGA();
            setShowAddComment2(true);
            dispatch(setShowAddComment(true));
        }
    }

    for (let i = 1; i < 6; i++) {
        stars[i] = <span key={i}
            className={message.rate >= i ? 'rating-icon full' : 'rating-icon empty'}
            onClick={() => clickStar(i)} />
    }

    return (
        <div className='message-section'>
            <p name='feed-title'>Share your feedback<br /> to help us improve VarChat</p>
            <span className={'display-align-center' + (wait ? ' disable-stars' : '')}>{stars}</span>
            <AddCommentModal ind={props.ind} stars={stars} addComment={{ showAddComment: showAddComment, setShowAddComment2: setShowAddComment2 }} />
        </div>
    );
}

export default Rating;